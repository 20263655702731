var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      staticClass: "page-container"
    },
    [
      _c(
        "el-row",
        { attrs: { gutter: 40 } },
        [
          _c("el-col", { attrs: { span: 12, offset: 6 } }, [
            _c("div", {}, [
              _c("h1", { staticClass: "page-header" }, [_vm._v("Company")])
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 40 } },
        [
          _c(
            "el-col",
            { attrs: { span: 12, offset: 6 } },
            [
              _c(
                "el-form",
                {
                  ref: "companyForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.companyForm,
                    rules: _vm.rules,
                    "label-width": "240px",
                    "label-position": "top"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Company Name", prop: "companyname" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.companyForm.companyname,
                          callback: function($$v) {
                            _vm.$set(_vm.companyForm, "companyname", $$v)
                          },
                          expression: "companyForm.companyname"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Address", prop: "address_line" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.companyForm.address_line,
                          callback: function($$v) {
                            _vm.$set(_vm.companyForm, "address_line", $$v)
                          },
                          expression: "companyForm.address_line"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "City", prop: "city" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.companyForm.city,
                          callback: function($$v) {
                            _vm.$set(_vm.companyForm, "city", $$v)
                          },
                          expression: "companyForm.city"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "State", prop: "state" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.companyForm.state,
                          callback: function($$v) {
                            _vm.$set(_vm.companyForm, "state", $$v)
                          },
                          expression: "companyForm.state"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "zipcode", prop: "zipcode" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.companyForm.zipcode,
                          callback: function($$v) {
                            _vm.$set(_vm.companyForm, "zipcode", $$v)
                          },
                          expression: "companyForm.zipcode"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Phone Number", prop: "phone_number" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.companyForm.phone_number,
                          callback: function($$v) {
                            _vm.$set(_vm.companyForm, "phone_number", $$v)
                          },
                          expression: "companyForm.phone_number"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: {
                            click: function($event) {
                              return _vm.submitForm("companyForm")
                            }
                          }
                        },
                        [_vm._v("Save")]
                      ),
                      _c(
                        "el-button",
                        {
                          on: {
                            click: function($event) {
                              return _vm.resetForm("companyForm")
                            }
                          }
                        },
                        [_vm._v("Reset")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }