var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main" }, [
    _c("br"),
    _c(
      "div",
      { attrs: { id: "error-header" } },
      [
        _c("h1", [_vm._v("404 - File not found")]),
        _c("h2", [
          _vm._v(
            " The requested page was not found. Ensure the URL you have entered is valid. "
          )
        ]),
        _c("br"),
        _c(
          "el-button",
          {
            staticClass: "homepage-btn",
            attrs: { type: "primary" },
            nativeOn: {
              click: function($event) {
                $event.preventDefault()
                return _vm.goHome.apply(null, arguments)
              }
            }
          },
          [_vm._v(" Return to homepage ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }