var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "page-container" },
    [
      _c(
        "el-row",
        { attrs: { gutter: 20 } },
        [
          _c("el-col", { attrs: { span: 6 } }, [
            _c("div", {}, [
              _c("h1", { staticClass: "page-header" }, [_vm._v("Companies")])
            ])
          ])
        ],
        1
      ),
      _c(
        "el-row",
        { attrs: { gutter: 40 } },
        [
          _c(
            "el-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.showPrint,
                  expression: "!showPrint"
                }
              ],
              attrs: { span: _vm.searchSpan }
            },
            [
              _c(
                "el-form",
                {
                  ref: "form",
                  attrs: {
                    "label-position": "top",
                    model: _vm.form,
                    "label-width": "120px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "Company Name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.companyname,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "companyname", $$v)
                          },
                          expression: "form.companyname"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "Company Type" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Comany Type" },
                          model: {
                            value: _vm.form.type,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "type", $$v)
                            },
                            expression: "form.type"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Technology", value: "technology" }
                          }),
                          _c("el-option", {
                            attrs: { label: "Healthcare", value: "healthcare" }
                          }),
                          _c("el-option", {
                            attrs: {
                              label: "Office Administration",
                              value: "office"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "State" } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "Comany Type" },
                          model: {
                            value: _vm.form.state,
                            callback: function($$v) {
                              _vm.$set(_vm.form, "state", $$v)
                            },
                            expression: "form.state"
                          }
                        },
                        [
                          _c("el-option", {
                            attrs: { label: "Nebraska", value: "NE" }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "City" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.form.city,
                          callback: function($$v) {
                            _vm.$set(_vm.form, "city", $$v)
                          },
                          expression: "form.city"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.submitForm }
                        },
                        [_vm._v("Search")]
                      ),
                      _c("el-button", { on: { click: _vm.resetForm } }, [
                        _vm._v("Clear")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: _vm.layoutSpan } },
            [
              _c(
                "el-button",
                {
                  staticClass: "add-button",
                  on: {
                    click: function($event) {
                      return _vm.AddCompany()
                    }
                  }
                },
                [_vm._v("Add A Company")]
              ),
              _c(
                "el-button",
                {
                  staticClass: "add-button hide-for-print",
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      return _vm.TogglePrintView()
                    }
                  }
                },
                [
                  _c("i", { staticClass: "el-icon-printer" }),
                  _vm._v(" Toggle Print View ")
                ]
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showPrint,
                      expression: "showPrint"
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: {
                    "show-header": false,
                    data: _vm.companies,
                    id: "data-table"
                  }
                },
                [
                  _c("el-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(prop) {
                          return [
                            _c("div", { staticClass: "data-container" }, [
                              _c(
                                "div",
                                { staticClass: "info" },
                                [
                                  _c("h3", { staticClass: "info-header" }, [
                                    _vm._v(_vm._s(prop.row.companyname))
                                  ]),
                                  _c(
                                    "el-row",
                                    { staticClass: "printer-friendly-row" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "printer-friendly-col" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "printer-friendly-col--label"
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(prop.row.address_line)
                                              )
                                            ]
                                          ),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "printer-friendly-col--label"
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(prop.row.city) +
                                                  ","
                                              )
                                            ]
                                          ),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "printer-friendly-col--label"
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(prop.row.zipcode)
                                              )
                                            ]
                                          ),
                                          _c("br")
                                        ]
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showMobile && !_vm.showPrint,
                      expression: "showMobile && !showPrint"
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { "show-header": false, data: _vm.companies }
                },
                [
                  _c("el-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(prop) {
                          return [
                            _c("div", { staticClass: "data-container" }, [
                              _c(
                                "div",
                                { staticClass: "info" },
                                [
                                  _c(
                                    "el-row",
                                    [
                                      _c("el-col", { attrs: { span: 22 } }, [
                                        _c(
                                          "h3",
                                          { staticClass: "info-header" },
                                          [_vm._v(_vm._s(prop.row.companyname))]
                                        ),
                                        _c("i", {
                                          staticClass:
                                            "el-icon-location-information"
                                        }),
                                        _c("span", [
                                          _vm._v(_vm._s(prop.row.address_line))
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            " " + _vm._s(prop.row.city) + ","
                                          )
                                        ]),
                                        _c("span", [
                                          _vm._v(" " + _vm._s(prop.row.zipcode))
                                        ]),
                                        _c("br"),
                                        _c("i", {
                                          staticClass: "el-icon-phone-outline"
                                        }),
                                        _c("span", [
                                          _vm._v(
                                            " " + _vm._s(prop.row.phone_number)
                                          )
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "div",
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "el-button--mini",
                                      attrs: {
                                        to: {
                                          name: "CompanyDetails",
                                          params: { id: prop.row.id }
                                        },
                                        tag: "el-button"
                                      }
                                    },
                                    [_vm._v("Details")]
                                  ),
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "mini", type: "danger" },
                                      on: {
                                        click: function($event) {
                                          return _vm.deleteCompany(
                                            _vm.table.row.id
                                          )
                                        }
                                      }
                                    },
                                    [_vm._v("Delete")]
                                  )
                                ],
                                1
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.showMobile && !_vm.showPrint,
                      expression: "!showMobile && !showPrint"
                    },
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loading,
                      expression: "loading"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { "show-header": true, data: _vm.companies }
                },
                [
                  _c("el-table-column", {
                    attrs: {
                      prop: "companyname",
                      label: "Company Name",
                      sortable: ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "address_line",
                      label: "Address",
                      sortable: ""
                    }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "city", label: "City", sortable: "" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "state", label: "State", sortable: "" }
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "phone_number", label: "Phone" }
                  }),
                  _c("el-table-column", {
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(prop) {
                          return [
                            _c(
                              "div",
                              [
                                _c(
                                  "router-link",
                                  {
                                    staticClass: "el-button--mini",
                                    attrs: {
                                      to: {
                                        name: "CompanyDetails",
                                        params: { id: prop.row.id }
                                      },
                                      tag: "el-button"
                                    }
                                  },
                                  [_vm._v("Details")]
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { size: "mini", type: "danger" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteCompany(prop.row.id)
                                      }
                                    }
                                  },
                                  [_vm._v("Delete")]
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c("pagination", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.total > 0,
                    expression: "total > 0"
                  }
                ],
                staticClass: "hide-for-print",
                attrs: {
                  total: _vm.total,
                  page: _vm.listQuery.page,
                  limit: _vm.listQuery.limit
                },
                on: {
                  "update:page": function($event) {
                    return _vm.$set(_vm.listQuery, "page", $event)
                  },
                  "update:limit": function($event) {
                    return _vm.$set(_vm.listQuery, "limit", $event)
                  },
                  pagination: _vm.getList
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }