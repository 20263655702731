<template id="user-add">
  <div class="page-container">
    <el-row :gutter="20">
      <el-col :span="12"
        ><div class=""><h1 class="page-header">My Profile</h1></div></el-col
      >
    </el-row>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form
          ref="user"
          :model="user"
          label-width="120px"
          label-position="top"
        >
          <el-form-item prop="username" label="Username">
            <el-input
              ref="username"
              name="username"
              placeholder="Username"
              v-model="user.username"
              :disabled="true"
            />
          </el-form-item>
          <el-form-item prop="name" label="User's Name">
            <el-input
              ref="name"
              name="name"
              placeholder="User's Name"
              v-model="user.name"
              :disabled="true"
            />
          </el-form-item>

          <el-form-item label="User's Email">
            <el-input v-model="user.email" :disabled="true" />
          </el-form-item>

          <el-form-item label="User's Phone">
            <el-input v-model="user.phone_number" :disabled="true" />
          </el-form-item>

          <el-form-item>
            <el-checkbox v-model="user.enablejobnotifications"
              >Enable Job Listing Notifications</el-checkbox
            >
            <el-checkbox v-model="user.enablecandidatenotifications"
              >Enable Candidate Listing Notifications</el-checkbox
            > </el-form-item
          ><br />

          <el-form-item>
            <el-button type="primary" @click="submitForm"
              >Save changes</el-button
            >
          </el-form-item>
        </el-form>
      </el-col>
      <el-col :span="12">
        <label>MFA Setup</label>
        <el-row v-if="preferredTOTP === 'NOMFA'">
          You can setup MFA below. Please use your preferred Software MFA.
          <section>
            <div class="columns is-vcentered">
              <div class="column is-half is-offset-one-quarter">
                <vue-qr :text="MFAString" :size="250"></vue-qr>
              </div>
            </div>
            <h2>No ability to scan the QR code?</h2>
            <p style="max-width: 95%; word-wrap: break-word">
              Enter the following Secret in your Multi-Factor Authentication
              App: {{ mfaSecret }}
            </p>
          </section>
          <el-input v-model="mfaCode" placeholder="MFA Code" />
          <el-button @click="confirmTOTP">Confirm MFA</el-button>
        </el-row>
        <el-row v-if="preferredTOTP === 'SOFTWARE_TOKEN_MFA'">
          <p>Thanks for setting up Software MFA!</p>
        </el-row>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
const api = require("../../api/api.js");

export default {
  components: {},
  name: "UserProfile",
  data() {
    return {
      userid: "dpacula",
      isLoaded: false,
      user: {
        username: "",
        email: "",
        name: "",
        phone_number: "",
        company: "",
        address: "",
        group: "Admin",
        isValid: false,
        enablejobnotifications: false,
        enablecandidatenotifications: false,
      },
      mfaCode: "",
      MFAString: "",
      mfaSecret: "",
      preferredTOTP: "",
    };
  },

  async mounted() {
    const currentUser = await Auth.currentAuthenticatedUser();
    this.preferredTOTP = await Auth.getPreferredMFA(currentUser);

    if (this.preferredTOTP === "NOMFA") {
      await this.changeTOTP();
    }
    this.user.username = currentUser.username;
    this.user.name = currentUser.attributes.name;
    this.user.email = currentUser.attributes.email;
    this.user.phone_number = currentUser.attributes.phone_number;
  },
  methods: {
    async submitForm() {
      this.$refs.user.validate(async (valid) => {
        if (valid) {
          try {
            await api.put("users/", this.user);
            this.$notify({
              title: "User updated Successfully!",
              message: "User was updated successfully.",
              type: "success",
            });
            this.$router.push("/users");
          } catch (err) {
            this.$notify.error({
              title: "Error",
              message: err,
            });
          }
        } else {
          console.error("Candidate details failed validation check.");
          return false;
        }
      });
    },

    async confirmTOTP() {
      const currentUser = await Auth.currentAuthenticatedUser();
      const challengeAnswer = this.mfaCode;

      try {
        await Auth.verifyTotpToken(currentUser, challengeAnswer).then(() => {
          Auth.setPreferredMFA(currentUser, "TOTP");
        });
        this.preferredTOTP = await Auth.getPreferredMFA(currentUser);
      } catch (error) {
        this.$notify.error("Error encountered during sign on.");
      }
    },
    async changeTOTP() {
      const currentUser = await Auth.currentAuthenticatedUser();
      await Auth.setupTOTP(currentUser).then((code) => {
        this.mfaSecret = code;
        this.MFAString =
          "otpauth://totp/AWSCognito:" +
          currentUser.username +
          "?secret=" +
          code +
          "&issuer=PayItForward";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.grid-content {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.vert-pad {
  padding-bottom: 20px;
}
</style>
