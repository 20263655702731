<template>
  <div>
    <div class="navWrapper">
      <div class="navbar" id="menu" :class="{ active: isActive }">
        <el-menu mode="horizontal" :router="true">
          <el-menu-item id="logo" index="/" :route="{ name: 'Jobs' }">
            <div class="main-logo">
              <i class="el-icon-d-arrow-right logo-icon" />
              <label class="logo-title">Pay It Forward</label>
            </div>
          </el-menu-item>
          <el-menu-item index="/jobs" :route="{ name: 'Jobs' }">
            Jobs
          </el-menu-item>
          <el-menu-item index="/candidate" :route="{ name: 'Candidates' }">
            Candidates
          </el-menu-item>
          <el-menu-item index="/company" :route="{ name: 'Companies' }">
            Company
          </el-menu-item>
          <el-menu-item index="/users" :route="{ name: 'Users' }">
            Users
          </el-menu-item>
        </el-menu>
      </div>
      <div id="toggle" @click="select()">
        <div class="span" id="top" :class="{ active: isActive }"></div>
        <div class="span" id="middle" :class="{ active: isActive }"></div>
        <div class="span" id="bottom" :class="{ active: isActive }"></div>
      </div>
      <div class="right-menu">
        <el-dropdown class="avatar-container" trigger="click">
          <div class="avatar-wrapper">
            <el-avatar shape="square" :size="40" :src="squareUrl"></el-avatar>
            <i class="el-icon-caret-bottom" />
          </div>
          <el-dropdown-menu slot="dropdown" class="user-dropdown">
            <router-link to="/">
              <el-dropdown-item> Home </el-dropdown-item>
            </router-link>
            <router-link to="/profile">
              <el-dropdown-item> My Profile </el-dropdown-item>
            </router-link>
            <el-dropdown-item divided @click.native.prevent="LogOut">
              <span style="display: block">Log Out</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <Breadcrumbs class="breadcrumb-container" />
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import Breadcrumbs from "../components/Breadcrumbs";
export default {
  components: {
    Breadcrumbs,
  },
  name: "Navbar",
  watch: {},
  data() {
    return {
      squareUrl:
        "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      isActive: false,
    };
  },
  methods: {
    async LogOut() {
      await Auth.signOut({ global: true });
      localStorage.clear();
      this.$store.commit("logOut");
      this.$router.push({ name: "Login", query: { redirect: "/login" } });
    },
  },
};
</script>

<style lang="scss" scoped>
.logo {
  display: inline-block;
}
.main-logo {
  background-color: #fb774f;
  color: white;
  font-weight: 700;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 14px;

  .logo-icon {
    margin-right: -4px;
  }
}
.navbar {
  overflow: hidden;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  color: #060606;

  .hamburger-container {
    line-height: 46px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }
}

.right-menu {
  position: absolute;
  float: right;
  right: 15px;
  line-height: 50px;
  top: 0px;

  &:focus {
    outline: none;
  }

  .right-menu-item {
    display: inline-block;
    padding: 0 8px;
    height: 100%;
    font-size: 18px;
    color: #5a5e66;
    vertical-align: text-bottom;

    &.hover-effect {
      cursor: pointer;
      transition: background 0.3s;

      &:hover {
        background: rgba(0, 0, 0, 0.025);
      }
    }
  }

  .avatar-container {
    margin-right: 30px;

    .avatar-wrapper {
      margin-top: 5px;
      position: relative;

      .user-avatar {
        cursor: pointer;
        width: 40px;
        height: 40px;
        border-radius: 10px;
      }

      .el-icon-caret-bottom {
        cursor: pointer;
        position: absolute;
        right: -20px;
        top: 25px;
        font-size: 12px;
      }
    }
  }
}

.el-menu--horizontal > .el-menu-item {
  float: left;
  height: 65px;
  line-height: 65px;
  margin: 0;
  border-bottom: 2px solid transparent;
  border-bottom-color: transparent;
  color: #060606;
  font-size: 18px;
}

@media (max-width: 767px) {
  .navWrapper {
    display: none;
  }
}
</style>
