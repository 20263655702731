import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  state: {
    user: {
      username: "",
      loggedIn: false,
      token: "",
      isAdmin: false,
      associatedId: "",
      userType: "",
    },
  },
  mutations: {
    logIn(state, payload) {
      state.user.loggedIn = payload.isLoggedIn;
      state.user.username = payload.username;
      state.user.token = payload.token;
      state.user.isAdmin = payload.isAdmin;
      state.user.associatedId = payload.associatedId;
      state.user.userType = payload.userType;
    },
    logOut(state) {
      state.user.loggedIn = false;
      state.user.username = "";
      state.user.token = "";
      state.user.isAdmin = false;
    },
  },
  getters: {
    getUserToken: (state) => {
      return state.user.token;
    },
    getAdminStatus: (state) => {
      return state.user.isAdmin;
    },
    getUsername: (state) => {
      return state.user.username;
    },
    getUserType: (state) => {
      return state.user.userType;
    },
  },
  actions: {},
  modules: {},
});
