<template>
  <div class="page-container">
    <el-row :gutter="20">
      <el-col :span="12" :offset="6">
        <div class=""><h1 class="page-header">Candidate</h1></div>
      </el-col>
    </el-row>
    <el-row v-loading="loading" :gutter="24">
      <el-col :span="12" :offset="6">
        <el-form
          :model="CandidateForm"
          :rules="rules"
          :label-position="'top'"
          ref="CandidateForm"
          label-width="240px"
          class="demo-ruleForm"
        >
          <el-form-item label="Candidate First Name" prop="firstname">
            <el-input v-model="CandidateForm.firstname" />
          </el-form-item>
          <el-form-item label="Candidate Last Name" prop="lastname">
            <el-input v-model="CandidateForm.lastname" />
          </el-form-item>
          <el-form-item label="Candidate's Field" prop="fieldid">
            <el-select v-model="CandidateForm.fieldid">
              <el-option
                v-for="field in fields"
                :key="field.fieldid"
                :label="field.field_name"
                :value="field.fieldid"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="Candidate Search Type" prop="categoryid">
            <el-select v-model="CandidateForm.categoryid">
              <el-option
                v-for="category in categories"
                :key="category.categories"
                :label="category.category_name"
                :value="category.categoryid"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="Sponsor"
            prop="pifsponsorid"
            placeholder="Select"
          >
            <el-select v-model="CandidateForm.pifsponsorid">
              <el-option
                v-for="sponsor in pifsponsors"
                :key="sponsor.rosterid"
                :label="sponsor.lastname"
                :value="String(sponsor.rosterid)"
              />
            </el-select>
          </el-form-item>
          <el-checkbox
            class="checks"
            v-model="resumeChecked"
            label="Upload a resume"
          />
          <el-checkbox
            class="checks"
            v-model="coverletterChecked"
            label="Include a Cover Letter"
          />
          <el-checkbox
            class="checks"
            v-model="supportingdocumentsChecked"
            label="Include supporting documents"
          />
          <br />
          <div v-if="resumeChecked">
            <div id="resume-bay">
              <el-form-item label="Upload a resume">
                <el-upload
                  ref="uploadresume"
                  :multiple="false"
                  drag
                  action="#"
                  :auto-upload="false"
                  :limit="1"
                  v-if="!showMobile"
                >
                  <i class="el-icon-upload" />
                  <div class="el-upload__text">
                    Drop file here or <em>click to upload</em>
                  </div>
                </el-upload>
                <el-upload
                  ref="uploadresume"
                  :multiple="false"
                  action="#"
                  :auto-upload="false"
                  :limit="1"
                  v-else
                >
                  <el-button size="tiny" type="primary">Click to browse</el-button>
                </el-upload>
              </el-form-item>
            </div>
            <el-button
              v-if="CandidateForm.id !== 0"
              type="primary"
              @click="
                handleUpload('resume', getDocumentsToUpload('resume').raw)
              "
              :loading="resumeUploading"
              >Upload</el-button
            >
            <div v-if="'resume' in this.CandidateForm.documentsList">
              <el-dialog
                title="Resume already on file"
                :visible.sync="resumeReplacer"
              >
                <span
                  >You already have a resume document on file: '{{
                    getSavedDocuments("resume").filename
                  }}'</span
                >
                <br /><br />
                <span
                  >You may opt to replace the currently saved document with the
                  new file, or cancel this upload attempt.</span
                >
                <span slot="footer" class="dialog-footer">
                  <el-button @click="resumeReplacer = false">Cancel</el-button>
                  <el-button
                    type="primary"
                    @click="
                      handleUpload(
                        'resume',
                        getDocumentsToUpload('resume').raw
                      )
                    "
                  >
                    Replace {{ getSavedDocuments("resume").filename }}
                  </el-button>
                </span>
              </el-dialog>
            </div>
          </div>
          <br />
          <div v-if="coverletterChecked">
            <div id="coverletter-bay">
              <el-form-item
                id="cover-letter-upload"
                label="Upload a cover letter"
              >
                <el-upload
                  ref="uploadcoverletter"
                  drag
                  :multiple="false"
                  action="#"
                  :auto-upload="false"
                  :limit="1"
                  v-if="!showMobile"
                >
                  <i class="el-icon-upload" />
                  <div class="el-upload__text">
                    Drop file here or <em>click to upload</em>
                  </div>
                </el-upload>
                <el-upload
                  ref="uploadcoverletter"
                  :multiple="false"
                  action="#"
                  :auto-upload="false"
                  :limit="1"
                  v-else
                >
                  <el-button size="tiny" type="primary">Click to browse</el-button>
                </el-upload>
              </el-form-item>
            </div>
            <el-button
              type="primary"
              v-if="CandidateForm.id !== 0"
              @click="
                handleUpload(
                  'coverletter',
                  getDocumentsToUpload('coverletter').raw
                )
              "
              :loading="coverletterUploading"
              >Upload</el-button
            >
            <div v-if="'coverletter' in this.CandidateForm.documentsList">
              <el-dialog
                title="Coverletter already on file"
                :visible.sync="coverletterReplacer"
              >
                <span
                  >You already have a coverletter document on file: '{{
                    getSavedDocuments("coverletter").filename
                  }}'</span
                >
                <br /><br />
                <span
                  >You may opt to replace the currently saved document with the
                  new file, or cancel this upload attempt.</span
                >
                <span slot="footer" class="dialog-footer">
                  <el-button @click="coverletterReplacer = false"
                    >Cancel</el-button
                  >
                  <el-button
                    type="primary"
                    @click="
                      handleUpload(
                        'coverletter',
                        getDocumentsToUpload('coverletter').raw
                      )
                    "
                  >
                    Replace {{ getSavedDocuments("coverletter").filename }}
                  </el-button>
                </span>
              </el-dialog>
            </div>
          </div>
          <div v-if="supportingdocumentsChecked">
            <div id="supportingdocuments-bay">
              <el-form-item
                id="additional-docs-upload"
                label="Additional/supporting documents"
              >
                <el-upload
                  ref="uploadsupportingdocuments"
                  drag
                  :multiple="true"
                  action="#"
                  :auto-upload="false"
                  :limit="2"
                  v-if="!showMobile"
                >
                  <i class="el-icon-upload" />
                  <div class="el-upload__text">
                    Drop file here or <em>click to upload</em>
                  </div>
                </el-upload>
                <el-upload
                  ref="uploadsupportingdocuments"
                  :multiple="true"
                  action="#"
                  :auto-upload="false"
                  :limit="2"
                  v-else
                >
                  <el-button size="tiny" type="primary">Click to browse</el-button>
                </el-upload>
              </el-form-item>
            </div>
            <el-button
              type="primary"
              v-if="CandidateForm.id !== 0"
              @click="
                handleUpload(
                  'supportingdocuments',
                  getDocumentsToUpload('supportingdocuments')
                )
              "
              :loading="supportingdocumentsUploading"
              >Upload</el-button
            >
            <!-- If there is at least one document on file AND the candidate is trying to upload more than one document, a document or two will have to be replaced -->
            <!-- With 2 documents and one upload, one file must be chosen for replacement -->
            <!-- With 1 document and two uploads, the saved file will be replaced -->
            <div
              v-if="'supportingdocuments' in this.CandidateForm.documentsList"
            >
              <el-dialog
                title="supporting document(s) already on file"
                :visible.sync="supportingdocumentsReplacer"
              >
                <span
                  >You already have one or more supporting documents on file:
                </span>
                <span
                  v-for="doc in CandidateForm.documentsList[
                    'supportingdocuments'
                  ]"
                  :key="doc.filename"
                >
                  '{{ doc.filename }}'
                </span>
                <br /><br />
                <span
                  v-if="
                    CandidateForm.documentsList['supportingdocuments'].length ==
                      1 &&
                    getDocumentsToUpload('supportingdocuments').length == 2
                  "
                >
                  By uploading the desired documents, the existing document on
                  file will be replaced. If you would like to keep the current
                  saved document, click 'cancel' below to cancel this attempt,
                  and remove one document from the upload queue in order to
                  upload one document and keep the currently saved document.
                </span>
                <span
                  v-else-if="
                    CandidateForm.documentsList['supportingdocuments'].length ==
                      2 &&
                    getDocumentsToUpload('supportingdocuments').length == 1
                  "
                >
                  In order to upload the desired document, an existing document
                  must be replaced. Please select a document to replace below,
                  or press 'cancel' to cancel this attempt.
                </span>
                <span
                  v-else-if="
                    CandidateForm.documentsList['supportingdocuments'].length ==
                      2 &&
                    getDocumentsToUpload('supportingdocuments').length == 2
                  "
                >
                  In order to upload the desired documents, both currently saved
                  documents must be replaced. If you would like to replace both
                  documents, you may do so. Otherwise, press 'cancel' to cancel
                  this attempt.
                </span>
                <span slot="footer" class="dialog-footer">
                  <div
                    v-if="
                      CandidateForm.documentsList['supportingdocuments']
                        .length == 2 &&
                      getDocumentsToUpload('supportingdocuments').length == 2
                    "
                  >
                    <el-button
                      type="primary"
                      @click="
                        replaceFile(
                          'supportingdocuments',
                          getDocumentsToUpload('supportingdocuments'),
                          'all'
                        )
                      "
                    >
                      Replace current documents
                    </el-button>
                  </div>
                  <div
                    v-else-if="
                      CandidateForm.documentsList['supportingdocuments']
                        .length == 2 &&
                      getDocumentsToUpload('supportingdocuments').length == 1
                    "
                  >
                    <el-button
                      v-for="(doc, index) in CandidateForm.documentsList[
                        'supportingdocuments'
                      ]"
                      type="primary"
                      :key="doc.filename"
                      @click="
                        replaceFile(
                          'supportingdocuments',
                          getDocumentsToUpload('supportingdocuments')[0],
                          CandidateForm.documentsList['supportingdocuments'][
                            index
                          ]
                        )
                      "
                    >
                      Replace {{ doc.filename }}
                    </el-button>
                  </div>
                  <div
                    v-else-if="
                      CandidateForm.documentsList['supportingdocuments']
                        .length == 1 &&
                      getDocumentsToUpload('supportingdocuments').length == 2
                    "
                  >
                    <el-button
                      type="primary"
                      @click="
                        replaceFile(
                          'supportingdocuments',
                          getDocumentsToUpload('supportingdocuments'),
                          'all'
                        )
                      "
                    >
                      Replace
                      {{
                        CandidateForm.documentsList["supportingdocuments"][0]
                          .filename
                      }}
                    </el-button>
                  </div>
                  <el-button @click="supportingdocumentsReplacer = false"
                    >Cancel</el-button
                  >
                </span>
              </el-dialog>
            </div>
          </div>
          <i class="fileupload-info">
            Acceptable file types: .pdf, .doc, .docx, .odt, .txt, .odt
          </i>
          <br />
          <i class="fileupload-info">Maximum file size: 100MB</i>
          <br />
          <i class="fileupload-info">Maximum two supporting documents</i>

          <el-form-item label="Notes" prop="notes">
            <el-input type="textarea" :row="3" v-model="CandidateForm.notes" />
          </el-form-item>
          <!-- Viewer component renders a button, which opens a dialog, which holds the document viewing controls -->
          <!-- Note that when the key value changes, the component is re-rendered 
            This is useful for handling a document being replaced, leading to a new view needing rendering -->
          <!-- To keep it simple, I have opted to use a simple 1 or 0, and simply flip this value, forcing the re-render -->
          <Viewer
            v-if="Object.keys(CandidateForm.documentsList).length > 0"
            :key="CandidateForm.documentsList.flip"
            :documentsList="CandidateForm.documentsList"
            :candidateId="CandidateForm.id"
          />
          <el-form-item>
            <el-button type="primary" @click="submitForm('CandidateForm')">
              Save
            </el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Viewer from "../../components/documents/Viewer.vue";
const api = require("../../api/api.js");

export default {
  name: "CandidateDetail",
  components: { Viewer },
  data() {
    return {
      resumeChecked: false,
      coverletterChecked: false,
      supportingdocumentsChecked: false,
      resumeReplacer: false,
      coverletterReplacer: false,
      supportingdocumentsReplacer: false,
      resumeUploading: false,
      coverletterUploading: false,
      supportingdocumentsUploading: false,
      CandidateForm: {
        id: 0,
        firstname: "",
        lastname: "",
        isconfidential: false,
        notes: "",
        pifsponsorid: null,
        fieldid: null,
        categoryid: null,
        is_deleted: false,
        documentsList: { flip: 0 },
        lastupdated: null,
      },
      fields: {},
      categories: {},
      pifsponsors: {},
      loading: false,
      screenWidth: window.innerWidth,
      showMobile: false,
      rules: {
        firstname: {
          required: true,
          message: "First name is required",
          trigger: "blur",
        },
        lastname: {
          required: true,
          message: "Last name is required",
          trigger: "blur",
        },
        pifsponsorid: {
          required: true,
          message: "A sponsor is required",
          trigger: "blur",
        },
        fieldid: {
          required: true,
          message: "Please select a field",
          trigger: "blur",
        },
        categoryid: {
          required: true,
          message: "Please select a search category",
          trigger: "blur",
        },
        notes: [
          {
            max: 2000,
            message: "Notes can be no longer than 2000 characters",
            trigger: "blur",
          },
        ],
      },
    };
  },
  async mounted() {
    window.onresize = () => {
      return (() => {
        window.screenWidth = window.innerWidth;
        this.screenWidth = window.screenWidth;
      })();
    };

    this.setLayout();
    this.CandidateForm.id = this.$route.params.id;
    this.loading = true;

    // Request all of the fields, sponsors, categories, ...
    const requests = [];
    requests.push(
      api.get("admin/field").then((data) => {
        this.fields = data;
      })
    );
    requests.push(
      api.get("admin/categories").then((data) => (this.categories = data))
    );
    requests.push(
      api.get("users?request=ids").then((data) => {
        this.pifsponsors = data.filter(
          sponsor => !sponsor.is_deleted
        );
      })
    );

    // Candidate already exists, download the candidate's data
    if (this.CandidateForm.id !== undefined) {
      requests.push(
        api.get("candidate/" + this.CandidateForm.id).then((data) => {
          // Move all values from data to the currently existing CForm object
          Object.assign(this.CandidateForm, data);
        })
      );
      // Get the candidate's document list
      requests.push(
        api
          .get(`documents/${this.CandidateForm.id}`)
          .then((data) => {
            this.CandidateForm.documentsList = data.documents;
          })
          .catch((e) => {
            console.error(e);
          })
      );
    } else {
      // new candidate, id of 0 lets postgres auto assign a nextVal for their ID
      this.CandidateForm.id = 0;
    }

    try {
      await Promise.all(requests);
    } catch (netErr) {
      this.$message.error(netErr.response.message);
    }

    this.loading = false;
  },
  watch: {
    // call again the method if the route changes
    screenWidth(val) {
      this.screenWidth = val;
      this.setLayout();
    },
  },
  methods: {
    setLayout() {
      if (this.screenWidth <= 767) {
        this.showMobile = true;
      } else {
        this.showMobile = false;
      }
    },
    /**
     * @param {doctype}
     * @return
     * Empty object: Nothing to upload.
     * Array of objects: multiple to upload (supporting documents)
     * File: One document to upload (resume, coverletter)
     */
    getDocumentsToUpload(doctype) {
      const refStr = `upload${doctype}`;
      // The upload element is active?
      if (
        typeof this.$refs[refStr] !== "undefined" &&
        typeof this.$refs[refStr].uploadFiles[0] !== "undefined"
      ) {
        const uploadFiles = this.$refs[refStr].uploadFiles;

        // Return the array of documents
        if (doctype === "supportingdocuments") {
          return uploadFiles;
        } else return uploadFiles[0];
        // Or just the single document for resume/cl
      } else {
        // Nothing to upload
        return {};
      }
    },

    /**
     * Convenience method for getting documents from docslist
     * @param {doctype}
     * @return {Any} the corresponding doc entry/array for doctype
     */
    getSavedDocuments(doctype) {
      return this.CandidateForm.documentsList[doctype];
    },

    /**
     * Convenience method for checking whether a replacement must take place in order to upload a file
     * @param {String} doctype
     * @param {Any} toUpload File(s) awaiting upload.
     * @return {Boolean} whether or not the replacement interface needs to open
     */
    _needToReplaceDocument(doctype, toUpload) {
      let need = false;
      if (doctype in this.CandidateForm.documentsList) {
        if (doctype === "supportingdocuments") {
          // >= 3 implies there are either two documents already on file and user is wanting to upload 1+
          // or there is 1 on file and user is wanting to upload 2
          // or there are 2 on file and user wants to upload 2... etc
          if (
            toUpload.length +
              this.CandidateForm.documentsList[doctype].length >=
            3
          ) {
            need = true;
          }
        } else need = true;
      }

      return need;
    },

    /**
     * Upload button click handler - This method will perform some checks to see if a document needs to be replaced,
     * and if so, open the replacement interface. Otherwise, the file(s) will be uploaded by insertion.
     * @param {String} doctype doctype of button, was this the resume 'upload' button? CL?...
     * @param {Any} toUpload File or files attempting to be uploaded
     * @return {Promise}
     */
    async handleUpload(doctype, toUpload) {
      // Nothing to upload, toUpload either undefined or empty object
      if (
        typeof toUpload === "undefined" ||
        Object.keys(toUpload).length === 0
      ) {
        return;
      }

      // Does a document need to be replaced? open the replacement interface if so, but only if the interface is not
      // already open
      const replacerStr = `${doctype}Replacer`;
      if (
        this._needToReplaceDocument(doctype, toUpload) === true &&
        this[replacerStr] === false
      )
        this[replacerStr] = true;
      else {
        try {
          this[doctype + "Uploading"] = true;
          await this._uploadTargetByInsertion(doctype, toUpload);
        } catch (netErr) {
          switch (netErr.response.status) {
            case 403:
              this.$message.error(netErr.response.data.message);
              break;
            default:
              this.$message.error(netErr.response.data.message);
              break;
          }

          this[doctype + "Uploading"] = false;
        }

        // close the upload bay
        this[doctype + "Checked"] = false;
        // Document(s) uploaded, fetch new list so the saved document data is accurate
        await this._updateDocsList();
        this[doctype + "Uploading"] = false;
      }
    },

    /**
     * Note this method only used on supportingdocuments calls. This is because resume/CL operations are always 'insert'.
     * This is due to the fact that there is only ever at one time a single resume/CL on file. We don't need any fancy
     * replacement logic to handle their operation.
     */
    async replaceFile(doctype, file, toReplace) {
      const docs = this.CandidateForm.documentsList[doctype];
      this[doctype + "Uploading"] = true;

      if (toReplace === "all") {
        // Replace operation for each file in cForm
        if (docs.length === 1 && file.length == 2) {
          // Just insert the rest
          const requests = [];
          file.forEach(async (f, index) => {
            // Use the first document to replace the current saved one
            if (index === 0) {
              requests.push(
                this._uploadTargetByReplacement(doctype, f.raw, docs[index])
              );
            } else {
              requests.push(this._uploadTargetByInsertion(doctype, f.raw));
            }
          });

          await Promise.all(requests);
        } else if (docs.length === 2 && file.length === 2) {
          // counts match, replace 1 : 1, this operation only works for supporting documents doctype
          const requests = [];
          file.forEach((entry, index) => {
            requests.push(
              this._uploadTargetByReplacement(doctype, entry.raw, docs[index])
            );
          });

          await Promise.all(requests);
        }
      } else {
        // source becomes target, solo operation
        await this._uploadTargetByReplacement(doctype, file.raw, toReplace);
      }

      // Close the upload bay, refresh the document list
      this[doctype + "Checked"] = false;
      await this._updateDocsList();
      this[doctype + "Uploading"] = false;
    },

    /**
     * This method works for both singular files and arrays of files. For single files, pass the file 'raw' or blob
     * as the toUpload argument. For 'toUpload' of type array, this method will unpack the array and call itself to
     * upload each individual file from the array.
     * @param {String} doctype the type of document to upload, resume, CL, sDoc, ..
     * @param {Any} toUpload a file blob to upload, or, an array of files
     */
    async _uploadTargetByInsertion(doctype, toUpload) {
      // close the replacement pane
      const replacerStr = `${doctype}Replacer`;
      this[replacerStr] = false;
      const requests = [];
      // If supportingdocuments, there may be multiple documents. in the case of 1, we will have an array w/ one element
      // we can just extract that element and move on. otherwise, we 'unpack' this array and use a bit of recursion to upload
      // each element of the array by pulling each element out of the array and calling this function with it as argument
      if (Array.isArray(toUpload)) {
        if (toUpload.length > 1) {
          toUpload.forEach(async (entry) => {
            // 2-layer iterative recursion - performance cost is negligent and saves a lot of of potential lines of code
            requests.push(this._uploadTargetByInsertion(doctype, entry.raw));
          });
        } else toUpload = toUpload[0].raw;
      } else {
        // Since this function works recursively for Array input (toUpload) ...
        if (!this.beforeDocUpload(toUpload, doctype)) {
          return;
        }

        const data = await api.get(
          `documents/${this.CandidateForm.id}/insert/?filename=${toUpload.name}&filetype=${toUpload.type}&doctype=${doctype}`
        );
        await fetch(data.url, {
          method: "PUT",
          headers: {
            "Content-Type": toUpload.type,
          },
          body: toUpload,
        });

        const h = this.$createElement;
        this.$notify({
          title: "Upload successful",
          message: h(
            "i",
            { style: "color: green" },
            `${toUpload.name} uploaded successfully.`
          ),
        });

      }
      // Note that for individual insertions, requests has one entry
      // however, if this was called with an array, it requests will contain each multiple entries
      // for each unpacked element of the array, and each *individual* call to this method will await
      // its network request, and the *master* call to this method will await each of the *individual* requests
      await Promise.all(requests);
    },

    /**
     * This method will fetch a current view of the candidate's document list
     * @return {Promise} Consumer may or may not care if this promise is awaited. Allowing this method to run
     * full asynch is not a bad idea.
     */
    async _updateDocsList() {
      const newDocData = await api.get(`documents/${this.CandidateForm.id}`);
      this.CandidateForm.documentsList = newDocData.documents;
      this.__flipDocListKey();
    },

    // Flip the doclist key to force Vue to re-render the component
    __flipDocListKey() {
      const list = this.CandidateForm.documentsList;
      list.flip = list.flip === 0 ? 1 : 0;
    },

    /**
     * @param {String} doctype
     * @param {File} target File blob to take the place of 'toReplace'
     * @param {Object} toReplace JS Object entry from documents list corresponding to the current S3 document to be replaced
     * @return {Promise}
     */
    async _uploadTargetByReplacement(doctype, target, toReplace) {
      // Close the replacement menu
      const replacerStr = `${doctype}Replacer`;
      this[replacerStr] = false;

      if (!this.beforeDocUpload(target, doctype)) {
        console.error("File uploading cancelled. Check error log.");
        return;
      }
      const data = await api.get(
        `documents/${this.CandidateForm.id}/replace/?target=${toReplace.key}&replacementName=${target.name}&replacementFileType=${target.type}&replacementDocType=${doctype}`
      );
      await fetch(data.url, {
        method: "PUT",
        headers: {
          "Content-Type": target.type,
        },
        body: target,
      });

      const h = this.$createElement;
      this.$notify({
        title: "Replacement successful",
        message: h(
          "i",
          { style: "color: green" },
          `${target.name} replaced ${toReplace.filename}.`
        ),
      });
    },

    /**
     * Verifies whether or not a supplied file is of the correct application type and file size
     * @param {File} file file to verify
     * @return {Boolean} whether or not the file is valid and can be used for uploading to S3
     */
    beforeDocUpload(file) {
      // MacOS as well as multiple various standards measure a megabyte as 10^6, however,
      // Windows users will note that their megabyte is measured as 1024^2. In keeping up
      // with more commonly followed networking standards, I have chosen to follow the former
      const STANDARD_MEGABYTE = Math.pow(10, 6);
      const VALID_EXTENSIONS = [
        "text/plain",
        "application/pdf",
        "application/msword", // .doc
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document", // .docx
        "application/rtf",
        "application/vnd.oasis.opendocument.text", // .odt, OpenOffice Document
      ];

      const isValidType = VALID_EXTENSIONS.includes(file.type);
      const isValidSize = file.size <= 100 * STANDARD_MEGABYTE;

      if (!isValidSize || !isValidType) {
        if (!isValidSize && !isValidType) {
          this.$message.error(`Uploaded file: ${file.name} is too large
          and of the incorrect format. Ensure all submitted files are at 
          most 100MB and of the following formats: .pdf, .doc, .docx, .odt, .txt, .odt`);
        } else {
          if (!isValidType) {
            this.$message.error(`Invalid file format submitted. Acceptable 
            filetypes: .pdf, .doc, .docx, .odt, .txt, .odt`);
          }
          if (!isValidSize) {
            this.$message.error("File is too large, maximum file size: 100MB");
          }
        }
      }

      return isValidType && isValidSize;
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            const cDate = new Date();
            const year = cDate.getUTCFullYear();
            const month = cDate.getUTCMonth() + 1;
            const day = cDate.getUTCDate();
            // Set the updated date
            this.CandidateForm.lastupdated = `${year}-${month}-${day}`;

            const response = await api.post("candidate", this.CandidateForm);
            
            // Check if resume has file to upload
            // Check if CL has file to upload
            // Check if SD has file(s) to upload
            if (this.CandidateForm.id === 0) {
              this.CandidateForm.id = response.candidateID;
              await Promise.all([
                this.handleUpload(
                  "resume",
                  this.getDocumentsToUpload("resume").raw
                ),
                this.handleUpload(
                  "coverletter",
                  this.getDocumentsToUpload("coverletter").raw
                ),
                this.handleUpload(
                  'supportingdocuments',
                  this.getDocumentsToUpload('supportingdocuments')
                ),
              ]);
            }

            // upload
            this.$notify.success({
              title: "Success",
              message: "Candidate saved sucessfully",
              offset: 100,
            });
            this.$router.push("/candidate");
          } catch (err) {
            this.$notify.error({
              title: "Save failed",
              message: "There was an error saving the candidate",
              offset: 100,
            });
          }
        } else {
          console.error("Candidate details failed validation check.");
          return false;
        }
      });
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/variables.scss";

.doc-view-panel {
  align-content: center;
  height: 800px;
  width: 600px;
}

#resume-bay {
  width: 240px;
  height: 280px;
  margin-bottom: 10px;
}

#coverletter-bay {
  width: 240px;
  height: 280px;
  margin-bottom: 10px;
}

#supportingdocuments-bay {
  width: 240px;
  height: 320px;
}

@media (max-width: 767px) {
  #resume-bay {
    width: 240px;
    height: 140px;
    margin-bottom: 10px;
  }

  #coverletter-bay {
    width: 80%;
    height: 140px;
    margin-bottom: 10px;
  }

  #supportingdocuments-bay {
    width: 240px;
    height: 180px;
  }
}

.checks {
  z-index: 1;
}

.fileupload-info {
  margin-top: 4px;
  margin-bottom: 4px;
  font-size: 12px;
  color: #606266;
}

.page-container {
  background-color: #f8f8f8;
  border-radius: 6px;
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>
