<template>
  <div class="home">
    <h1>Welcome to Pay It Foward.</h1>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",
  components: {},
};
</script>

<style lang="scss">
h1 {
  padding: 5px;
  font-weight: 400;
  font-size: 28px;
}
</style>
