var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "login-container" },
      [
        _c(
          "el-form",
          {
            ref: "loginForm",
            staticClass: "login-form",
            attrs: {
              model: _vm.loginForm,
              rules: _vm.loginRules,
              "auto-complete": "on",
              "label-position": "left"
            }
          },
          [
            _c("div", { staticClass: "title-container" }, [
              _c("h3", { staticClass: "title" }, [_vm._v("Pay It Forward")])
            ]),
            _c(
              "el-form-item",
              { attrs: { prop: "username" } },
              [
                _c("span", { staticClass: "svg-container" }, [
                  _c("i", { staticClass: "el-icon-user-solid" })
                ]),
                _c("el-input", {
                  ref: "username",
                  attrs: {
                    placeholder: "Username",
                    name: "username",
                    type: "text",
                    tabindex: "1",
                    "auto-complete": "on"
                  },
                  model: {
                    value: _vm.loginForm.username,
                    callback: function($$v) {
                      _vm.$set(_vm.loginForm, "username", $$v)
                    },
                    expression: "loginForm.username"
                  }
                })
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "password" } },
              [
                _c("span", { staticClass: "svg-container" }, [
                  _c("i", { staticClass: "el-icon-unlock" })
                ]),
                _c("el-input", {
                  key: _vm.passwordType,
                  ref: "password",
                  attrs: {
                    type: _vm.passwordType,
                    placeholder: "Password",
                    name: "password",
                    tabindex: "2",
                    "auto-complete": "on"
                  },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.SignIn.apply(null, arguments)
                    }
                  },
                  model: {
                    value: _vm.loginForm.password,
                    callback: function($$v) {
                      _vm.$set(_vm.loginForm, "password", $$v)
                    },
                    expression: "loginForm.password"
                  }
                }),
                _c(
                  "span",
                  {
                    staticClass: "show-password",
                    on: { click: _vm.showPassword }
                  },
                  [
                    _c("i", {
                      class:
                        _vm.passwordType === "password"
                          ? "el-icon-view"
                          : "el-icon-lock"
                    })
                  ]
                )
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { prop: "resetcode" } },
              [
                _c("span", { staticClass: "svg-container" }, [
                  _c("i", { staticClass: "el-icon-unlock" })
                ]),
                _c("el-input", {
                  ref: "password",
                  attrs: {
                    placeholder: "Reset Code",
                    name: "resetcode",
                    tabindex: "2",
                    "auto-complete": "on"
                  },
                  nativeOn: {
                    keyup: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.SignIn.apply(null, arguments)
                    }
                  },
                  model: {
                    value: _vm.loginForm.resetcode,
                    callback: function($$v) {
                      _vm.$set(_vm.loginForm, "resetcode", $$v)
                    },
                    expression: "loginForm.resetcode"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              "margin-bottom": "30px",
                              width: "100%"
                            },
                            attrs: { loading: _vm.loading, type: "primary" },
                            nativeOn: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.SignIn.apply(null, arguments)
                              }
                            }
                          },
                          [_vm._v("Change Password")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }