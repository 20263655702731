<template>
  <div id="viewer" ref="viewer">
    <ul>
      <li v-if="'resume' in documentsList">
        <div v-if="!showMobile">
          <el-button type="primary" @click="loadViewer('resume')">
            View Resume
          </el-button>
          <el-dialog
            class="viewer-dialog"
            title="Resume Viewer"
            :visible.sync="resumeDialog"
          >
            <div class="viewer-full-window">
              <div
                id="resume-viewer-window"
                ref="resume-viewer-window"
                class="view-pane"
              >
                <h3>{{ documentsList["resume"].filename }}:</h3>
              </div>
            </div>
            <br />
            <div slot="footer" class="dialog-footer">
              <el-button
                :loading="!loadedDocuments['resume']"
                @click="downloadDoc('resume')"
                type="primary"
                ref="resume-test"
              >
                Download {{ documentsList["resume"].filename }}
              </el-button>
              <el-button @click="resumeDialog = false" type="danger"
                >Close viewer</el-button
              >
            </div>
          </el-dialog>
        </div>
        <div class="mobile-download-box" v-else>
          <h3>Saved Resume</h3>
          <el-button size="mini" type="primary" @click="downloadDoc('resume')">
            {{ documentsList["resume"].filename }}
          </el-button>
        </div>
      </li>

      <li v-if="'coverletter' in documentsList">
        <div v-if="!showMobile">
          <el-button type="primary" @click="loadViewer('coverletter')">
            View Cover Letter
          </el-button>
          <el-dialog
            class="viewer-dialog"
            title="Cover Letter Viewer"
            :visible.sync="clDialog"
          >
            <div class="viewer-full-window">
              <div
                id="coverletter-viewer-window"
                ref="coverletter-viewer-window"
                class="view-pane"
              >
                <h3>{{ documentsList["coverletter"].filename }}:</h3>
              </div>
            </div>
            <br />
            <div slot="footer" class="dialog-footer">
              <el-button
                :loading="!loadedDocuments['coverletter']"
                @click="downloadDoc('coverletter')"
                type="primary"
              >
                Download {{ documentsList["coverletter"].filename }}
              </el-button>
              <el-button @click="clDialog = false" type="danger"
                >Close viewer</el-button
              >
            </div>
          </el-dialog>
        </div>
        <div class="mobile-download-box" v-else>
          <h3>Saved Cover Letter</h3>
          <el-button
            size="mini"
            type="primary"
            @click="downloadDoc('coverletter')"
          >
            {{ documentsList["coverletter"].filename }}
          </el-button>
        </div>
      </li>

      <li v-if="'supportingdocuments' in documentsList">
        <div v-if="!showMobile">
          <el-button type="primary" @click="loadViewer('supportingdocuments')">
            View Supporting Docs
          </el-button>
          <el-dialog
            class="viewer-dialog"
            title="Supporting Documents Viewer"
            :visible.sync="sdDialog"
          >
            <div
              v-bind:class="supportingDocumentsViewerWindow()"
              v-for="(document, index) in documentsList['supportingdocuments']"
              :key="index"
            >
              <div
                :id="'supportingdocuments-viewer-window-' + index"
                :ref="'supportingdocuments-viewer-window-' + index"
                class="view-pane"
              >
                <h3>{{ document.filename }}:</h3>
              </div>
            </div>
            <br />
            <div slot="footer" class="dialog-footer">
              <el-button
                v-for="(document, index) in documentsList[
                  'supportingdocuments'
                ]"
                :key="index"
                :ref="'supportingdocuments-download-button-' + index"
                :loading="!loadedDocuments['supportingdocuments']"
                @click="downloadDoc('supportingdocuments', index)"
                type="primary"
              >
                Download {{ document.filename }}
              </el-button>
              <el-button @click="sdDialog = false" type="danger"
                >Close viewer</el-button
              >
            </div>
          </el-dialog>
        </div>
        <div class="mobile-download-box" v-else>
          <h3>Saved Supporting Documents</h3>
          <div
            v-for="(document, index) in documentsList['supportingdocuments']"
            :key="index"
          >
            <el-button
              size="mini"
              type="primary"
              @click="downloadDoc('supportingdocuments', index)"
            >
              {{ document.filename }}
            </el-button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import WebViewer from "@pdftron/webviewer";
const api = require("../../api/api.js");

// Prop documentsList: {doctype: {document}, doctype: [{document}, {document}]}
export default {
  name: "Viewer",
  props: ["documentsList", "candidateId"],
  data() {
    return {
      resumeDialog: false,
      clDialog: false,
      sdDialog: false,
      dialogVisible: false,
      loadedDocuments: {
        resume: false,
        coverletter: false,
        supportingdocuments: false,
      },
      screenWidth: window.innerWidth,
      showMobile: false,
    };
  },
  async mounted() {
    window.onresize = () => {
      return (() => {
        window.screenWidth = window.innerWidth;
        this.screenWidth = window.screenWidth;
      })();
    };
    this.setLayout();
  },
  methods: {
    setLayout() {
      if (this.screenWidth <= 767) {
        this.showMobile = true;
      } else {
        this.showMobile = false;
      }
    },
    supportingDocumentsViewerWindow() {
      const docList = this.documentsList.supportingdocuments;

      if (docList.length == 1) {
        return "viewer-full-window";
      } else if (docList.length == 2) {
        return "viewer-split-window";
      } else {
        throw {
          message: "Too many documents detected!",
        };
      }
    },
    /**
     * Throws 'unexpected index parameter'
     */
    async downloadDoc(doctype, index) {
      const entry = this.documentsList[doctype];
      await this._downloadBlobs(doctype);

      let blob;
      let filename;
      // If the docslist entry for this document is an array
      // we must acceess its props by index
      if (Array.isArray(entry)) {
        blob = entry[index].blob;
        filename = entry[index].filename;
      } else {
        // Index supplied? Why.. entry isn't an array, throw an err
        if (typeof index !== "undefined")
          throw {
            message: 'unexpected argument "index" supplied at "downloadDoc()"',
          };
        blob = entry.blob;
        filename = entry.filename;
      }

      const elem = document.createElement("a");

      // Create a temporary a element, simulate a click
      // this starts the download of the file for the user
      elem.href = URL.createObjectURL(blob);
      elem.download = filename;
      document.body.appendChild(elem);
      elem.click();
      // We simulated a click. Remove the element, otherwise, subsequent clicks will perform
      // two, or three, or 30 download attempts from the client
      document.body.removeChild(elem);
    },

    async _downloadBlobs(doctype) {
      // The property corresponding to the desired document in the documentsList prop
      const entry = this.documentsList[doctype];
      // Already downloaded
      if (doctype === "supportingdocuments" && entry[0].blob) return;
      else if (entry.blob) return;
      // Fetch the specific document(s) for this doctype (resume, coverletter, suppdocs...)
      const data = await api.get(
        `documents/${this.candidateId}/fetch?doctype=${doctype}`
      );

      const responseBody = data.documents;

      if (doctype === "supportingdocuments") {
        // Downloaded doctype documents list
        const requests = [];
        responseBody.forEach((doc, index) => {
          requests.push(this.__downloadBlob(entry[index], doc.link));
          // Generated blob DL requests
        });

        // Wait for all the blobs to download
        await Promise.all(requests);
      } else {
        await this.__downloadBlob(entry, responseBody.link);
      }
    },
    // Utility function for downloading a blob (or series of blobs) and storing it
    // in the appropriate 'documents' data property.
    async __downloadBlob(doc, link) {
      return await fetch(link)
        .then((response) => response.blob())
        .then((blob) => {
          doc.blob = blob;
        });
    },
    // Set the el-dialog's sync variable to true, rendering the dialog
    // and then, if a webviewer does not already exist for the document
    // create one using _loadWebViewer
    async loadViewer(doctype) {
      switch (doctype) {
        case "resume":
          this.resumeDialog = true;
          break;
        case "coverletter":
          this.clDialog = true;
          break;
        case "supportingdocuments":
          this.sdDialog = true;
          break;
      }

      // Wait for DOM to finish rendering - required due to the way
      // el-dialog renders it's children after it's sync variable changes
      this.$nextTick(async () => {
        // User has opted to view a document. First, we must check if this document
        // has already been 'loaded'. This entails downloading the document as well
        // as generating the WebViewer, or in the case of rtf, odt, txt, a special
        // DOM element to inform them of why they cannot view these types in browser.
        if (!this.loadedDocuments[doctype]) {
          try {
            const docsListProp = this.documentsList[doctype];
            await this._downloadBlobs(doctype);

            if (doctype === "supportingdocuments") {
              // For each suppdoc, we generate a viewer element
              this.documentsList[doctype].forEach((doc, index) => {
                // Blobs downloaded
                const extension = this._lookupFileExt(doc.filetype);
                const refStr = `${doctype}-viewer-window-${index}`;
                // Creating elements using v-for assigns each element's ref property
                // as an array, in this case, each array only has a single element
                const viewerRef = this.$refs[refStr][0];
                const viewerParams = {
                  blob: doc.blob,
                  extension,
                  filename: doc.filename,
                  doctype,
                  viewerRef,
                };
                this._generateViewerElements(viewerParams);
              });
            } else {
              const blob = this.documentsList[doctype].blob;
              const extension = this._lookupFileExt(docsListProp.filetype);
              const refStr = `${doctype}-viewer-window`;
              const viewerRef = this.$refs[refStr];
              const viewerParams = {
                blob,
                extension,
                filename: docsListProp.filename,
                doctype,
                viewerRef,
              };

              this._generateViewerElements(viewerParams);
            }

            // A webviewer instance was loaded for this (these) document (documents)
            // we do not want to load a new webviewer instance next time the user
            // opens the dialog. This will also update the state of the download
            // button from loading to loaded.
            this.loadedDocuments[doctype] = true;
          } catch (fileDownloadError) {
            console.error(fileDownloadError);
            this.$message.error(fileDownloadError.message);
            //! An error occured during the file download process. Regardless of which
            //! step it failed at, any sort of viewer will be impossible to render.
            const ParentElement = this.$refs["viewer"];
            const p = document.createElement("p");
            const message = `Error: '${fileDownloadError.message}' encountered while loading file. \
              You may wish to retry viewing this file in a few minutes.`;
            p.innerHTML = message;
            ParentElement.appendChild(p);
          }
        }
      });
    },
    _lookupFileExt(filetype) {
      // Utility function for mapping MIMEtype to WebViewer ext.
      // Parse out extension type from MIME for WebViewer
      // WebViewer does not support .txt, .odt, .rtf
      // (.rtf and .odt supported only using WebViewer server)
      switch (filetype) {
        case "application/pdf":
          return "pdf";
        case "application/msword":
          return "doc";
        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          return "docx";
        case "text/plain":
          return "plaintext";
        case "application/vnd.oasis.opendocument.text":
          return "odt";
        case "application/rtf":
          return "rtf";
        default:
          return "UNSUPPORTED";
      }
    },

    _generateViewerElements(params) {
      const { blob, extension, filename, viewerRef } = params;
      const path = `${process.env.BASE_URL}webviewer`; // path to the webviewer resources

      switch (extension) {
        case "pdf":
        case "doc":
        case "docx":
          WebViewer({ path }, viewerRef).then((instance) => {
            instance.UI.loadDocument(blob, { filename, extension });
            const docViewer = instance.Core.documentViewer;

            docViewer.addEventListener("documentLoaded", () => {
              instance.UI.setZoomLevel("100%");
            });
          });
          break;
        case "plaintext":
          // Extension not supported by webviewer
          // for plaintext we can use a regular 'object' element
          // for rtf & odt we say 'no ty sir'
          // Put in a block to avoid 'hoisting' variables.
          {
            const objectViewer = document.createElement("object");
            const objectDiv = document.createElement("div");
            objectViewer.data = URL.createObjectURL(blob); // object 'data' property requires a url
            objectDiv.appendChild(objectViewer);
            viewerRef.appendChild(objectDiv);
          }
          break;
        case "rtf":
        case "odt":
          {
            const errorDiv = document.createElement("div");
            const errorParagraph = document.createElement("p");
            const message = `${extension} not supported for in-browser viewing. You may still download this file using \
              the buttons below to manually view it.`;
            errorParagraph.innerHTML = message;
            errorDiv.appendChild(errorParagraph);
            viewerRef.appendChild(errorDiv);
          }
          break;
        default:
          this.$message.error(
            "Error: Unsupported filetype for document detected. " +
              "You may still download this document, but recommend uploading a new one of supported filetype."
          );
          console.error(
            "If you are seeing this, contact a Jelecos developer with " +
              "the following information: An invalid filetype was processed by extension check in webapp."
          );
          // This code is put in a new block, as variables in case statements are visible for the
          // entire switch block. In other words, the scope of these variables is limited to this case only.
          {
            const errorDiv = document.createElement("div");
            const errorParagraph = document.createElement("p");
            const message = `File-type for this document not supported. You may still download this file using \
              the buttons below to manually view it. However, recommend user upload a new file of the correct file-type \
              to replace this invalid file.`;
            errorParagraph.innerHTML = message;
            errorDiv.appendChild(errorParagraph);
            viewerRef.appendChild(errorDiv);
          }
          break;
      }
    },
  },
};
</script>

<style lang="scss">
$viewer-child-margin: 0px 12px 0px 12px;
$main-bg-color: #f8f8f8;

#viewer {
  box-sizing: border-box;

  ul {
    list-style-type: none;
    margin: 4;
    padding: 0;
    overflow: hidden;
  }

  li {
    float: left;
  }

  .mobile-download-box {
    width: 125px;
    margin: 2px 4px 2px 0px;
    background-color: white;
    box-shadow: 3px 2px 2px black;
    box-sizing: border-box;
    padding: 0px 4px;
    text-align: center;

    button {
      margin: 2px auto;
    }

    h3 {
      font-size: 14px;
    }
  }

  .el-dialog {
    background-color: $main-bg-color;
  }

  .el-dialog__title {
    border-bottom: 1px solid black;
    text-decoration: underline;
    font-size: 24px;
    font-weight: 700;
  }

  .viewer-dialog {
    .el-dialog__body {
      padding: 0;
      height: 1000px;
      box-sizing: border-box;

      .viewer-full-window {
        height: 90%;
      }

      .viewer-split-window {
        margin: auto;
        height: 50%;
      }

      .view-pane {
        box-sizing: border-box;
        height: 100%;
        align-content: center;
        text-align: center;

        h3 {
          box-sizing: border-box;
          font-size: 32px;
          font-weight: 500;
          color: black;
          margin: $viewer-child-margin;
          height: 10%;
        }

        div {
          box-sizing: border-box;
          height: 90%;
        }

        iframe {
          height: 90%;
          box-sizing: border-box;
        }

        object {
          box-sizing: border-box;
          display: block;
          border: 1px solid black;
          margin-left: auto;
          margin-right: auto;
          height: 100%;
          width: 100%;
        }

        p {
          margin: $viewer-child-margin;
          font-size: 18px;
          word-break: break-word;
          white-space: prewrap;
        }
      }
    }
  }
}
</style>
