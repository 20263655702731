<template id="user-add">
  <div class="page-container">
    <el-row :gutter="20">
      <el-col :span="6"
        ><div class=""><h1 class="page-header">Edit User</h1></div></el-col
      >
    </el-row>
    <Details v-if="isLoaded" :isNew="false" :username="userid" />
  </div>
</template>

<script>
import Details from "../../components/Users/Details.vue";

export default {
  components: {
    Details,
  },
  name: "userdetails",
  data() {
    return {
      userid: "",
      isLoaded: false,
    };
  },

  mounted() {
    this.userid = this.$route.params.username;
    this.isLoaded = true;
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.grid-content {
  display: flex;
  justify-content: center;
  margin-top: 25px;
}

.vert-pad {
  padding-bottom: 20px;
}
</style>
