var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "forgot-password-container" }, [
    _c("div", { staticClass: "forgot-password-header" }, [
      _c("h2", [_vm._v("Pay It Forward")]),
      _c("h2", { staticClass: "h-title" }, [_vm._v("Forgot Password")]),
      _vm.isSuccess
        ? _c("div", { staticClass: "success-prompt notification is-success" }, [
            _c("p", [
              _vm._v(
                " Request successfully sent. If your username is in the system, an email will be sent with instructions. Taking you back to the Login screen shortly. "
              )
            ])
          ])
        : _vm._e()
    ]),
    _c(
      "div",
      { staticClass: "forgot-password-body" },
      [
        _c(
          "el-form",
          {
            ref: "forgotForm",
            staticClass: "login-form",
            attrs: {
              model: _vm.forgotForm,
              rules: _vm.rules,
              "auto-complete": "on",
              "label-position": "left"
            },
            nativeOn: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.ForgotPassword.apply(null, arguments)
              }
            }
          },
          [
            _c(
              "el-form-item",
              { attrs: { prop: "username" } },
              [
                _c("span", { staticClass: "svg-container" }, [
                  _c("i", { staticClass: "el-icon-user-solid" })
                ]),
                _c("el-input", {
                  ref: "mfaCode",
                  attrs: {
                    placeholder: "Username",
                    name: "username",
                    type: "text",
                    tabindex: "1",
                    "auto-complete": "on"
                  },
                  model: {
                    value: _vm.forgotForm.username,
                    callback: function($$v) {
                      _vm.$set(_vm.forgotForm, "username", $$v)
                    },
                    expression: "forgotForm.username"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              [
                _c(
                  "el-row",
                  { attrs: { gutter: 20 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-button",
                          {
                            staticStyle: {
                              "margin-bottom": "30px",
                              width: "100%"
                            },
                            attrs: { type: "primary" },
                            on: { click: _vm.ForgotPassword }
                          },
                          [_vm._v(" Forgot Password ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }