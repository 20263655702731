import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";

import "@/styles/index.scss"; // global css

import Amplify, * as AmplifyModules from "aws-amplify";
import { AmplifyPlugin } from "aws-amplify-vue";
import { Auth } from "aws-amplify";

import VueQr from "vue-qr";

Amplify.configure({
  region: "us-east-1",
  userPoolId: "us-east-1_IEWI5Ac6W",
  userPoolWebClientId: "4v82mab2smvriapctcnct8dkgb",
});
Vue.config.productionTip = false;

Vue.use(Auth);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.component("vue-qr", VueQr);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  VueQr,
  render: (h) => h(App),
}).$mount("#app");
