// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Arimo:wght@400;700&display=swap);"]);
// Module
exports.push([module.id, "/* fade */\n.fade-enter-active,\n.fade-leave-active {\n  transition: opacity 0.28s; }\n\n.fade-enter,\n.fade-leave-active {\n  opacity: 0; }\n\n/* fade-transform */\n.fade-transform-leave-active,\n.fade-transform-enter-active {\n  transition: all .5s; }\n\n.fade-transform-enter {\n  opacity: 0;\n  transform: translateX(-30px); }\n\n.fade-transform-leave-to {\n  opacity: 0;\n  transform: translateX(30px); }\n\n/* breadcrumb transition */\n.breadcrumb-enter-active,\n.breadcrumb-leave-active {\n  transition: all .5s; }\n\n.breadcrumb-enter,\n.breadcrumb-leave-active {\n  opacity: 0;\n  transform: translateX(20px); }\n\n.breadcrumb-move {\n  transition: all .5s; }\n\n.breadcrumb-leave-active {\n  position: absolute; }\n\nbody {\n  height: 100%;\n  font-family: 'Arimo', sans-serif;\n  margin: 0;\n  background-color: #f8f8f8; }\n\n.info {\n  margin-bottom: 10px; }\n\n.info-header {\n  font-size: 20px;\n  margin: 0;\n  margin-bottom: 5px; }\n  .info-header:hover {\n    color: white; }\n\n.info-icon {\n  font-size: 4em; }\n\n@media (max-width: 767px) {\n  .el-table tr {\n    background: #f8f8f8 !important; } }\n\n.data-container {\n  background-color: white;\n  border-radius: 6px;\n  padding: 10px;\n  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px; }\n\n.add-button {\n  float: right;\n  margin-right: 10px; }\n\n.input-tel__label {\n  position: absolute;\n  top: -10px !important;\n  cursor: pointer;\n  left: 13px;\n  transform: translateY(25%);\n  opacity: 0;\n  transition: all 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);\n  font-size: 11px;\n  color: #747474; }\n", ""]);
// Exports
exports.locals = {
	"menuText": "#bfcbd9",
	"menuActiveText": "#409EFF",
	"subMenuActiveText": "#f4f4f5",
	"menuBg": "#304156",
	"menuHover": "#263445",
	"subMenuBg": "#f3f4f0",
	"subMenuHover": "#f3f4f0",
	"sideBarWidth": "210px"
};
module.exports = exports;
