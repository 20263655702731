<template>
  <el-row :gutter="20" v-loading="loading">
    <el-col :span="12">
      <el-form
        ref="user"
        :model="user"
        :rules="formRules"
        label-width="120px"
        label-position="top"
      >
        <el-form-item prop="username" label="Username">
          <el-input
            ref="username"
            name="username"
            placeholder="username"
            v-model="user.username"
            :disabled="!isNew"
          />
        </el-form-item>
        <el-form-item prop="firstname" label="User's First Name">
          <el-input
            ref="firstname"
            name="firstname"
            placeholder="User's First Name"
            v-model="user.firstname"
          />
        </el-form-item>
        <el-form-item prop="lastname" label="User's Last Name">
          <el-input
            ref="name"
            name="lastname"
            placeholder="User's Last Name"
            v-model="user.lastname"
          />
        </el-form-item>
        <el-form-item prop="email" label="User's Email">
          <el-input
            ref="email"
            name="email"
            placeholder="User's Email"
            v-model="user.email"
          />
        </el-form-item>
        <el-form-item label="Company" prop="companyid" placeholder="Select">
          <el-select
            v-model="user.companyid"
            placeholder="Select"
            ref="companyid"
          >
            <el-option
              v-for="company in companies"
              :key="company.id"
              :label="company.companyname"
              :value="company.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="mobilephone" label="Cell Phone Number">
          <the-mask 
            v-model="user.mobilephone"
            label="Phone Number"
            required
            outlined
            clearable
            mask="+# (###) ###-####" />
        </el-form-item>
        <el-form-item prop="businessphone" label="Business Phone Number">
          <the-mask 
            v-model="user.businessphone"
            label="Phone Number"
            required
            outlined
            clearable
            mask="+# (###) ###-####" />
        </el-form-item>

        <el-form-item>
          <el-checkbox v-model="user.enablejobnotifications"
            >Enable Job Listing Notifications</el-checkbox
          >
          <el-checkbox v-model="user.enablecandidatenotifications"
            >Enable Candidate Listing Notifications</el-checkbox
          > </el-form-item
        ><br />

        <el-form-item>
          <el-button v-if="isNew" type="primary" @click="createUser"
            >Create</el-button
          >
          <el-button v-if="!isNew" type="primary" @click="updateUser"
            >Update</el-button
          >
          <el-button @click="onCancel">Cancel</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
const api = require("../../api/api.js");
import {TheMask} from 'vue-the-mask'

export default {
  name: "Details",
  components: {TheMask},
  props: {
    isNew: {
      type: Boolean,
      required: true,
    },
    username: {
      type: String,
      required: false,
    },
  },
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value === "" || value === null) {
        callback(new Error("This field is required."));
      } else {
        callback();
      }
    };

    const validateMobile = (rule, value, callback) => {
      if (!value) {
        callback(new Error("This field is required."));
      } else {
        if (value.length === 11) {
          callback();
        }
        callback(new Error("Invalid Phone Number."));
      }
    };

    const validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("Please enter an email address"));
      } else if (!this.validEmailRegex.test(value)) {
        callback(new Error("Please enter a valid email address"));
      } else {
        callback();
      }
    };
    return {
      user: {
        username: "",
        email: "",
        name: "",
        phone_number: "",
        verified_email: "",
        verified_phone: "",
        status: "",
        create_date: "",
        last_modified: "",
        group: "Admin",
        firstname: "",
        lastname: "",
        companyid: "",
        pifleadid: "",
        businessphone: "",
        mobilephone: "",
        is_deleted: false,
        enablejobnotifications: false,
        enablecandidatenotifications: false,
      },
      companies: {},
      validmobilephone: false,
      validbusinessphone: false,
      validEmailRegex:
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/, //eslint-disable-line no-useless-escape
      formRules: {
        username: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        firstname: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        lastname: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        companyid: [
          { required: true, trigger: "blur", validator: validateUsername },
        ],
        email: [{ required: true, trigger: "blur", validator: validateEmail }],
        mobilephone: [
          { required: true, trigger: "blur", validator: validateMobile },
        ],
        businessphone: [
          { required: true, trigger: "blur", validator: validateMobile },
        ],
      },
      payees: [],
      payors: [],
      isPayee: false,
      isPayor: false,
      loading: false,
      callCounter: 0,
    };
  },
  watch: {
    user: {
      handler() {
        this.userTypeChange();
      },
      deep: true,
    },
  },
  async mounted() {
    this.loading = true;
    this.companies = await api.get("company");

    if (!this.isNew) {
      await this.getUser(this.username);
    }

    this.loading = false;
  },
  methods: {
    async createUser() {
      let router = this.$router;

      await this.validateForm();

      if (this.isValid) {
        try {
          this.user.mobilephone = `+${this.user.mobilephone}`;
          this.user.businessphone = `+${this.user.businessphone}`;
          await api.post("users/", this.user);
          this.$notify({
            title: "User Created Successfully!",
            message:
              "User was created successfully and sent login information.",
            type: "success",
          });
          router.push("/users");
        } catch (err) {
          this.$notify.error({
            title: "Error",
            message:
              "Failed to save user. Ensure all fields are formatted and filled correctly.",
          });
        }
      } else {
        this.$notify.error({
          title: "Error",
          message:
            "Invalid form data submitted. Ensure all fields are formatted and filled correctly.",
        });
      }
    },

    async updateUser() {
      await this.validateForm();

      if (this.isValid) {
        try {
          await api.put("users/", this.user);
          this.$notify({
            title: "User updated Successfully!",
            message: "User was updated successfully.",
            type: "success",
          });
          this.$router.push("/users");
        } catch (err) {
          this.$notify.error({
            title: "Error",
            message: err,
          });
        }
      }
    },

    async getUser(username) {
      try {
        this.user = await api.get("users/" + username);
        this.user.username = this.user.user_name;
        this.user.companyid = parseInt(this.user.companyid);

      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: err.message,
        });
      }
    },

    async userTypeChange() {
      if (this.user.group === "Payee") {
        this.isPayee = true;
        this.isPayor = false;
      } else if (this.user.group === "Payor") {
        this.isPayee = false;
        this.isPayor = true;
      } else {
        this.isPayee = false;
        this.isPayor = false;
      }
    },

    async validateForm() {
      this.$refs.user.validate((valid) => {
        this.isValid = valid;
      });
    },
    onCancel() {
      let router = this.$router;
      router.push("/users");
    },
  },
};
</script>

<style>
.country-selector__label {
  transform: translateY(-25%) !important;
}
</style>
