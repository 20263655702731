var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-row",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      attrs: { gutter: 20 }
    },
    [
      _c(
        "el-col",
        { attrs: { span: 12 } },
        [
          _c(
            "el-form",
            {
              ref: "user",
              attrs: {
                model: _vm.user,
                rules: _vm.formRules,
                "label-width": "120px",
                "label-position": "top"
              }
            },
            [
              _c(
                "el-form-item",
                { attrs: { prop: "username", label: "Username" } },
                [
                  _c("el-input", {
                    ref: "username",
                    attrs: {
                      name: "username",
                      placeholder: "username",
                      disabled: !_vm.isNew
                    },
                    model: {
                      value: _vm.user.username,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "username", $$v)
                      },
                      expression: "user.username"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "firstname", label: "User's First Name" } },
                [
                  _c("el-input", {
                    ref: "firstname",
                    attrs: {
                      name: "firstname",
                      placeholder: "User's First Name"
                    },
                    model: {
                      value: _vm.user.firstname,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "firstname", $$v)
                      },
                      expression: "user.firstname"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "lastname", label: "User's Last Name" } },
                [
                  _c("el-input", {
                    ref: "name",
                    attrs: {
                      name: "lastname",
                      placeholder: "User's Last Name"
                    },
                    model: {
                      value: _vm.user.lastname,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "lastname", $$v)
                      },
                      expression: "user.lastname"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "email", label: "User's Email" } },
                [
                  _c("el-input", {
                    ref: "email",
                    attrs: { name: "email", placeholder: "User's Email" },
                    model: {
                      value: _vm.user.email,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "email", $$v)
                      },
                      expression: "user.email"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "Company",
                    prop: "companyid",
                    placeholder: "Select"
                  }
                },
                [
                  _c(
                    "el-select",
                    {
                      ref: "companyid",
                      attrs: { placeholder: "Select" },
                      model: {
                        value: _vm.user.companyid,
                        callback: function($$v) {
                          _vm.$set(_vm.user, "companyid", $$v)
                        },
                        expression: "user.companyid"
                      }
                    },
                    _vm._l(_vm.companies, function(company) {
                      return _c("el-option", {
                        key: company.id,
                        attrs: { label: company.companyname, value: company.id }
                      })
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { prop: "mobilephone", label: "Cell Phone Number" } },
                [
                  _c("the-mask", {
                    attrs: {
                      label: "Phone Number",
                      required: "",
                      outlined: "",
                      clearable: "",
                      mask: "+# (###) ###-####"
                    },
                    model: {
                      value: _vm.user.mobilephone,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "mobilephone", $$v)
                      },
                      expression: "user.mobilephone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    prop: "businessphone",
                    label: "Business Phone Number"
                  }
                },
                [
                  _c("the-mask", {
                    attrs: {
                      label: "Phone Number",
                      required: "",
                      outlined: "",
                      clearable: "",
                      mask: "+# (###) ###-####"
                    },
                    model: {
                      value: _vm.user.businessphone,
                      callback: function($$v) {
                        _vm.$set(_vm.user, "businessphone", $$v)
                      },
                      expression: "user.businessphone"
                    }
                  })
                ],
                1
              ),
              _c(
                "el-form-item",
                [
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.user.enablejobnotifications,
                        callback: function($$v) {
                          _vm.$set(_vm.user, "enablejobnotifications", $$v)
                        },
                        expression: "user.enablejobnotifications"
                      }
                    },
                    [_vm._v("Enable Job Listing Notifications")]
                  ),
                  _c(
                    "el-checkbox",
                    {
                      model: {
                        value: _vm.user.enablecandidatenotifications,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.user,
                            "enablecandidatenotifications",
                            $$v
                          )
                        },
                        expression: "user.enablecandidatenotifications"
                      }
                    },
                    [_vm._v("Enable Candidate Listing Notifications")]
                  )
                ],
                1
              ),
              _c("br"),
              _c(
                "el-form-item",
                [
                  _vm.isNew
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.createUser }
                        },
                        [_vm._v("Create")]
                      )
                    : _vm._e(),
                  !_vm.isNew
                    ? _c(
                        "el-button",
                        {
                          attrs: { type: "primary" },
                          on: { click: _vm.updateUser }
                        },
                        [_vm._v("Update")]
                      )
                    : _vm._e(),
                  _c("el-button", { on: { click: _vm.onCancel } }, [
                    _vm._v("Cancel")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }