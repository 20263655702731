<template>
  <div class="main">
    <br />
    <div id="error-header">
      <h1>404 - File not found</h1>
      <h2>
        The requested page was not found. Ensure the URL you have entered is
        valid.
      </h2>
      <br />
      <el-button
        type="primary"
        class="homepage-btn"
        @click.native.prevent="goHome"
      >
        Return to homepage
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",
  methods: {
    goHome() {
      this.$router.push({
        name: "Home",
        params: {
          setupMFA: false,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 1rem;
  background-color: #f8f8f8;
}

h1 {
  font-weight: bold;
}
h2 {
  font-weight: normal;
}

.homepage-btn {
  width: 15%;
  margin-bottom: 30px;
}

#button-arrow {
  color: grey;
  text-align: center;
  font-size: 18px;
  font-style: normal;
}

#error-header {
  margin: auto;
  text-align: center;
  width: 60%;
  font-weight: bold;
  padding: 2rem;
}

#homepage-button {
  font-size: 16px;
  color: white;
  border: 2px solid black;
  background-color: #fb774f;
}
</style>
