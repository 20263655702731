var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "app-wrapper" }, [
        _c(
          "div",
          { staticClass: "main-container" },
          [_c("div", [_c("navbar")], 1), _c("app-main")],
          1
        )
      ]),
      _c("div", { staticClass: "mobile-nav-seperator" }),
      _c("mobile-nav")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }