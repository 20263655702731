<template>
  <div v-loading="loading" class="page-container">
    <el-row :gutter="40">
      <el-col :span="12" :offset="6"
        ><div class=""><h1 class="page-header">Company</h1></div></el-col
      >
    </el-row>
    <el-row :gutter="40">
      <el-col :span="12" :offset="6">
        <el-form
          :model="companyForm"
          :rules="rules"
          ref="companyForm"
          label-width="240px"
          :label-position="'top'"
          class="demo-ruleForm"
        >
          <el-form-item label="Company Name" prop="companyname">
            <el-input v-model="companyForm.companyname"></el-input>
          </el-form-item>
          <el-form-item label="Address" prop="address_line">
            <el-input v-model="companyForm.address_line"></el-input>
          </el-form-item>
          <el-form-item label="City" prop="city">
            <el-input v-model="companyForm.city"></el-input>
          </el-form-item>
          <el-form-item label="State" prop="state">
            <el-input v-model="companyForm.state"></el-input>
          </el-form-item>
          <el-form-item label="zipcode" prop="zipcode">
            <el-input v-model="companyForm.zipcode"></el-input>
          </el-form-item>
          <el-form-item label="Phone Number" prop="phone_number">
            <el-input v-model="companyForm.phone_number"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('companyForm')"
              >Save</el-button
            >
            <el-button @click="resetForm('companyForm')">Reset</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
const api = require("../../api/api.js");
export default {
  name: "CompanyDetail",
  components: {},
  data() {
    return {
      loading: false,
      companyForm: {
        id: 0,
        companyname: "",
        address_line: null,
        city: null,
        state: null,
        zipcode: null,
        phone_number: null,
        is_deleted: false,
      },
      rules: {
        companyname: [
          {
            required: true,
            message: "Please input company name",
            trigger: "blur",
          },
          {
            min: 3,
            max: 50,
            message: "Length should be 3 to 50",
            trigger: "blur",
          },
        ],
      },
    };
  },
  async mounted() {
    this.loading = true;
    this.companyForm.id = this.$route.params.id;

    if (this.companyForm.id !== undefined) {
      const companyDetails = await api.get("company/" + this.companyForm.id);
      this.companyForm = companyDetails;
    } else {
      this.companyForm.id = 0;
    }
    this.loading = false;
  },
  methods: {
    async submitCompany() {
      try {
        await api.post("company", this.companyForm);
        this.$router.push("/company");
      } catch (e) {
        this.$notify.error({
          title: "Error",
          message:
            "Error occurred submitting company data. Check your connection and then try again later.",
        });
      }
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          await this.submitCompany();
        } else {
          this.$notify.error({
            title: "Error",
            message:
              "Failed to save company. Ensure all fields are formatted and filled correctly.",
          });
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scoped>
.page-container {
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
</style>
