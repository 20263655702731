const axios = require("axios");
//import { Auth } from "aws-amplify";

/*async function getAuthToken() {
  var user = await Auth.currentSession();
  return user.idToken.jwtToken;
}*/

export async function get(page) {
  //const idToken = await getAuthToken();
  let toReturn = await axios.get(process.env.VUE_APP_SERVICE_URL + page, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + idToken,
    },
  });

  return toReturn.data;
}

export async function getById(page, id) {
  //const idToken = await getAuthToken();
  let toReturn = await axios.get(
    process.env.VUE_APP_SERVICE_URL + page + "/" + id,
    {
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + idToken,
      },
    }
  );

  return toReturn.data;
}

export async function post(page, data) {
  //const idToken = await getAuthToken();
  //console.log(process.env.VUE_APP_SERVICE_URL);
  let toReturn = await axios.post(
    process.env.VUE_APP_SERVICE_URL + page,
    data,
    {
      headers: {
        "Content-Type": "application/json",
        // Authorization: "Bearer " + idToken,
      },
    }
  );

  return toReturn.data;
}

export async function put(page, data) {
  //const idToken = await getAuthToken();
  let toReturn = await axios.put(process.env.VUE_APP_SERVICE_URL + page, data, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + idToken,
    },
  });

  return toReturn.data;
}

export async function remove(page, id) {
  //const idToken = await getAuthToken();

  return await axios.delete(process.env.VUE_APP_SERVICE_URL + page + "/" + id, {
    headers: {
      "Content-Type": "application/json",
      // Authorization: "Bearer " + idToken,
    },
  });
}
