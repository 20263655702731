<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      signedIn: false,
    };
  },
  async beforeCreate() {},
  created() {
    document.title = "Pay It Forward";
  },
};
</script>
<style lang="scss">
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
