<template>
  <div class="forgot-password-container">
    <div class="forgot-password-header">
      <h2>Pay It Forward</h2>
      <h2 class="h-title">Forgot Password</h2>
      <div v-if="isSuccess" class="success-prompt notification is-success">
        <p>
          Request successfully sent. If your username is in the system, an email
          will be sent with instructions. Taking you back to the Login screen
          shortly.
        </p>
      </div>
    </div>
    <div class="forgot-password-body">
      <el-form
        ref="forgotForm"
        :model="forgotForm"
        :rules="rules"
        class="login-form"
        auto-complete="on"
        label-position="left"
        @submit.prevent.native="ForgotPassword"
      >
        <el-form-item prop="username">
          <span class="svg-container">
            <i class="el-icon-user-solid"></i>
          </span>
          <el-input
            ref="mfaCode"
            v-model="forgotForm.username"
            placeholder="Username"
            name="username"
            type="text"
            tabindex="1"
            auto-complete="on"
          />
        </el-form-item>
        <div>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-button 
              type="primary" 
              style="margin-bottom: 30px; width: 100%"
              @click="ForgotPassword">
                Forgot Password
              </el-button>
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: "ForgotPassword",
  components: {},
  data() {
    return {
      forgotForm: {
        username: "",
        password: "",
      },
      rules: {
        username: [{ required: true, trigger: "blur" }],
      },
      authenticated: false,
      username: "",
      user: "",
      isSuccess: false,
    };
  },
  mounted() {},
  methods: {
    async ForgotPassword(e) {
      try {
        e.preventDefault();
        await Auth.forgotPassword(this.forgotForm.username);
        this.isSuccess = true;
        setTimeout(
          () =>
            this.$router.push({
              name: "ChangePassword",
              query: { redirect: "/changepassword" },
            }),
          4000
        );
      } catch (err) {
        switch (err.name) {
          case "UserNotFoundException":
            this.$notify.error({
              title: "Invalid username",
              message: "Username not found.",
              duration: 7_500,
            });
            break;
          case "AuthError":
            this.$notify.error({
              title: "Empty username",
              message: "Username cannot be empty.",
              duration: 7_500,
            });
            break;
          default:
            this.$notify.error({
              title: "Error",
              message: "Error communicating with authentication service.",
              duration: 7_500,
            });
            break;
        }
      }
    },
  },
};
</script>

<style lang="scss">
/* reset element-ui css */
.forgot-password-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      height: 47px;
    }
  }

  .el-form-item {
    border: 1px solid rgba(38, 164, 3, 0.1);
    background: rgba(238, 164, 3, 0.1);
    color: #454545;
  }

  .forgot {
    color: #060606;

    & :hover {
      color: #060606;
    }
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.forgot-password-container {
  height: 100%;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .forgot-password-header {
    text-align: center;
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .forgot-password-body {
    position: relative;
    width: 520px;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;

    .svg-container {
      padding: 6px 5px 6px 15px;
      color: $dark_gray;
      vertical-align: middle;
      width: 30px;
      display: inline-block;
    }
  }
}

.helper {
  position: absolute;
  font-size: 0.8em;
  right: 15px;
}

.divider {
  height: 100%;
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center;
}

.columns {
  height: 100%;
}

h1 {
  font-weight: 900;
  font-size: 1em;
}

h2 {
  font-size: 2em;
  font-weight: 900;
  margin-bottom: 20px;
}

.signin {
  margin-top: 50px;
}
</style>
