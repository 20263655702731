<template>
  <div>
    <div v-if="!confirmMFASetup" class="login-container">
      <el-form
        ref="registrationForm"
        :model="registrationForm"
        :rules="loginRules"
        class="login-form"
        auto-complete="on"
        label-position="left"
      >
        <div class="title-container">
          <h3 class="title">Pay It Forward.</h3>
        </div>

        <el-form-item prop="legalName">
          <span class="svg-container">
            <i class="el-icon-user-solid"></i>
          </span>
          <el-input
            ref="legalName"
            v-model="registrationForm.legalName"
            placeholder="Name"
            name="username"
            type="text"
            tabindex="1"
            auto-complete="on"
          />
        </el-form-item>

        <el-form-item prop="username">
          <span class="svg-container">
            <i class="el-icon-user-solid"></i>
          </span>
          <el-input
            ref="username"
            v-model="registrationForm.username"
            placeholder="Username"
            name="username"
            type="text"
            tabindex="1"
            auto-complete="on"
          />
        </el-form-item>

        <el-form-item prop="oldPassword">
          <span class="svg-container">
            <s<i class="el-icon-unlock" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="registrationForm.oldPassword"
            :type="passwordType"
            placeholder="Provided System Password"
            name="oldPassword"
            tabindex="2"
            auto-complete="on"
          />
          <span class="show-password" @click="showPassword">
            <i
              :class="
                passwordType === 'password' ? 'el-icon-view' : 'el-icon-lock'
              "
            />
          </span>
        </el-form-item>
        <el-form-item prop="newPassword">
          <span class="svg-container">
            <s<i class="el-icon-unlock" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="registrationForm.newPassword"
            :type="passwordType"
            placeholder="Your Password"
            name="newPassword"
            tabindex="2"
            auto-complete="on"
          />
          <span class="show-password" @click="showPassword">
            <i
              :class="
                passwordType === 'password' ? 'el-icon-view' : 'el-icon-lock'
              "
            />
          </span>
        </el-form-item>
        <p class="password-requirements">
          passwords must meet the following requirements:
          Minimum of 8 characters in length, 
          include one lowercase letter, one uppercase letter, and one special character
          at minimum. Special characters may be selected from the following list: 
          {{ specialCharacterList }}
        </p>
        <el-form-item prop="confirmPassword">
          <span class="svg-container">
            <s<i class="el-icon-unlock" />
          </span>
          <el-input
            :key="passwordType"
            ref="password"
            v-model="registrationForm.confirmPassword"
            :type="passwordType"
            placeholder="Confirm Your Password"
            name="confirmPassword"
            tabindex="2"
            auto-complete="on"
          />
          <span class="show-password" @click="showPassword">
            <i
              :class="
                passwordType === 'password' ? 'el-icon-view' : 'el-icon-lock'
              "
            />
          </span>
        </el-form-item>
        <div>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-button
                :loading="loading"
                type="primary"
                style="margin-bottom: 30px; width: 100%"
                @click.native.prevent="submitForm('registrationForm')"
                >Complete Registration</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
    <div v-if="confirmMFASetup" class="login-container">
      <el-form
        ref="mfaForm"
        :model="mfaForm"
        class="login-form"
        auto-complete="on"
        label-position="left"
      >
        <div class="title-container">
          <h3 class="title">Pay It Forward.</h3>
        </div>

        <el-form-item prop="legalName">
          <span class="svg-container">
            <i class="el-icon-user-solid"></i>
          </span>
          <el-input
            ref="legalName"
            v-model="mfaForm.mfaCode"
            placeholder="Confirm MFA Code"
            name="username"
            type="text"
            tabindex="1"
            auto-complete="on"
          />
        </el-form-item>
        <div>
          <el-row :gutter="20">
            <el-col :span="24">
              <el-button
                :loading="loading"
                type="primary"
                style="margin-bottom: 30px; width: 100%"
                @click.native.prevent="confirmMFACode"
                >Confirm MFA Code</el-button
              >
            </el-col>
          </el-row>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";

export default {
  name: "Registration",
  props: {
    setupMFA: {
      type: Boolean,
    },
    reAuthMFA: {
      type: Boolean,
    },
    awsUser: {
      type: Object,
    },
    loginUsername: {
      type: String,
    },
    loginPassword: {
      type: String,
    },
    mfaStringRegProp: {
      type: String,
    },
  },
  data() {
    const validatePassword = (_rule, password, callback) => {
      if (password.length < 8) {
        callback(new Error(`Entered password is not long enough.`));
      }

      for (const exp of this.passwordRegularExpressions) {
        if (!exp.test(password)) {
          callback(new Error(`Password does not conform to policy.`));
        }
      }

      callback();
    }

    const validateConfirmPassword = (_rule, confirmationPassword, callback) => {
      if (this.registrationForm.newPassword !== confirmationPassword) {
        callback(new Error('Passwords do not match.'));
      }

      callback();
    }

    const validateUsername = (_rule, username, callback) => {
      if (!username) {
        callback(new Error('This field is required. Please enter your PIF username.'));
      }
      
      callback();
    }

    const validateLegalName = (_rule, name, callback) => {
      if (!name) {
        callback(new Error('This field is required. Please enter your first and last name.'));
      }
      
      callback();
    }

    return {
      signedIn: false,
      isValid: false,
      registrationForm: {
        legalName: "",
        username: this.loginUsername,
        oldPassword: this.loginPassword,
        newPassword: "",
        confirmPassword: "",
        err: "",
      },
      mfaForm: {
        mfaCode: "",
      },
      msg: "",
      loginRules: {
        legalName: [{ required: true, trigger: "blur", validator: validateLegalName }],
        username: [{ required: true, trigger: "blur", validator: validateUsername }],
        newPassword: [{ required: true, trigger: "blur", validator: validatePassword }],
        confirmPassword: [{ required: true, trigger: "blur", validator: validateConfirmPassword }]
      },
      specialCharacterList: `^$*.[\]{}()?\-"!@#%&\/\,><':;|_~\`+=)`,
      passwordRegularExpressions: [
        /[$*.[\]{}()?\-"!@#%&\/\,><':;|_~`+=)]+/,
        /[0-9]+/,
        /[A-Z]+/,
        /[a-z]+/
      ],
      loading: false,
      passwordType: "password",
      redirect: undefined,
      associatedId: "",
      authenticated: false,
      mfaCode: "",
      confirmMFASetup: this.confirmMFA,
      user: "",
      isError: false,
      isMFAError: false,
      mfaType: "SMS_MFA",
    };
  },
  watch: {
    $route: {
      handler: function (route) {
        this.redirect = route.query && route.query.redirect;
      },
      immediate: true,
    },
  },
  methods: {
    showPassword() {
      if (this.passwordType === "password") {
        this.passwordType = "";
      } else {
        this.passwordType = "password";
      }
      this.$nextTick(() => {
        this.$refs.password.focus();
      });
    },

    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          try {
            const user = await Auth.signIn(
              this.registrationForm.username,
              this.registrationForm.oldPassword
            );

            await Auth.completeNewPassword(user, this.registrationForm.confirmPassword, {
              name: this.registrationForm.legalName,
            });

            await Auth.signOut();

            this.$router.push({ path: "/" });
          } catch (error) {
            if (error.message.includes("Password does not conform to policy") ||
                  error.message == "Incorrect username or password." || 
                  error.message.startsWith("Insufficient password strength:")) 
            {
              this.$notify.error({
                title: "Validation Error",
                message: error.message,
                duration: 12_500,
              });          
            } else {
              this.$notify.error({
                title: "Validation Error",
                message: "Missing required field. Ensure all fields are filled out completely.",
                duration: 12_500,
              });     
            }
          }          
        } else {
            this.$notify.error({
              title: "Validation Error",
              message: `Form validation failed, ensure username and password are filled out \
                and new password conforms to policy.`,
              duration: 12_500,
          });
        }
      })
    },
    async confirmMFACode() {
      const user = this.mfaConfirmUser;
      const code = this.mfaForm.mfaCode;

      try {
        await Auth.confirmSignIn(
          user, // Return object from Auth.signIn()
          code, // Confirmation code
          "SMS_MFA"
        ).then(async () => {
          this.$store.commit("logIn", {
            isLoggedIn: true,
            username: this.registrationForm.username,
            token: user.signInUserSession.accessToken.jwtToken,
            isAdmin:
              user.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes("Admin"),
            userType:
              user.signInUserSession.accessToken.payload["cognito:groups"][0],
          });
          this.$router.push({ name: "Home" });
        });
      } catch (err) {
        this.$notify.error("Error encountered during sign on.");
        this.isMFAError = true;
        throw err;
      }
    },
    async validateForm() {
      this.$refs.registrationForm.validate((valid) => {
        this.isValid = valid;
      });
    },
  },
};
</script>

<style lang="scss">
.login-container .el-input input {
  color: black !important;
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      height: 47px;
    }
  }

  .el-form-item {
  }

  .forgot {
    color: #060606;

    & :hover {
      color: #060606;
    }
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.password-requirements {
  color: grey;
  font-size: 11px;
}

.login-container {
  min-height: 100%;
  width: 100%;
  overflow: hidden;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .login-error {
    color: white;
    width: 100%;
    padding-bottom: 20px;
    padding-left: 80px;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-password {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
}

.el-button {
  padding: 16px 20px;
  border-radius: 0;
}

.el-form-item__content {
  line-height: 40px;
  position: relative;
  font-size: 14px;
  background-color: white;
  border-color: #dbfa55;
  color: black !important;
}
</style>
