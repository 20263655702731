<template>
  <div v-loading="loading" class="page-container">
    <el-row :gutter="20">
      <el-col :span="12" :offset="6">
        <el-form
          :model="jobForm"
          :rules="rules"
          ref="jobForm"
          label-width="180px"
          class="demo-ruleForm"
        >
          <el-form-item label="Job Title" prop="jobtitle">
            <el-input v-model="jobForm.jobtitle"></el-input>
          </el-form-item>
          <el-form-item label="Posting Company" prop="companyid">
            <el-select
              v-model="jobForm.companyid"
              placeholder="Select"
              ref="companyid"
            >
              <el-option
                v-for="company in companies"
                :key="company.id"
                :label="company.companyname"
                :value="company.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Number of Openings" prop="jobopenings">
            <el-input v-model="jobForm.jobopenings"></el-input>
          </el-form-item>
          <el-form-item label="Status" prop="categoryid" placeholder="Select">
            <el-select
              v-model="jobForm.categoryid"
              placeholder="Select"
              ref="sponsors"
            >
              <el-option
                v-for="category in categories"
                :key="category.categoryid"
                :label="category.category_name"
                :value="category.categoryid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Sponsor"
            prop="pifsponsorid"
            placeholder="Select"
          >
            <el-select
              v-model="jobForm.pifsponsorid"
              placeholder="Select"
              ref="sponsors"
            >
              <el-option
                v-for="sponsor in pifsponsors"
                :key="sponsor.rosterid"
                :label="sponsor.lastname"
                :value="sponsor.rosterid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Field" prop="fieldid">
            <el-select v-model="jobForm.fieldid" placeholder="Select" ref="field">
              <el-option
                v-for="field in fields"
                :key="field.fieldid"
                :label="field.field_name"
                :value="field.fieldid"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Subcategory" prop="subcategory">
            <el-input v-model="jobForm.subcategory"></el-input>
          </el-form-item>
          <el-form-item label="Notes" prop="notes">
            <el-input type="textarea" v-model="jobForm.notes"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitForm('jobForm')"
              >Save</el-button
            >
            <el-button @click="resetForm('jobForm')">Clear</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>

<script>
const api = require("../../api/api.js");
export default {
  name: "JobsDetail",
  components: {},
  data() {
    const validateOpenings = (rule, value, callback) => {
      if (isNaN(value)) {
        callback(new Error("This field must be numeric."));
      } else if (parseInt(value) < 0) {
        callback(new Error("This field must be a positive integer."));
      } else {
        callback();
      }
    };
    return {
      jobForm: {
        id: 0,
        companyid: 0,
        jobtitle: "",
        jobopenings: 0,
        pifsponsorid: 0,
        fieldid: 0,
        subcategory: "",
        notes: "",
        rosterid: 0,
        categoryid: 1,
        lastupdated: null,
      },
      rules: {
        jobopenings: {
          required: true,
          message: "Please select a number of available openings",
          trigger: "blur",
          validator: validateOpenings,
        },
        categoryid: {
          required: true,
          message: "Please select a job status",
          trigger: "blur",
        },
        pifsponsorid: {
          required: true,
          message: "Please select a sponsor",
          trigger: "blur",
        },
        companyid: {
          required: true,
          message: "Please select a company",
          trigger: "blur",
        },
        fieldid: {
          required: true,
          message: "Please select a field",
          trigger: "blur",
        },
        jobtitle: [
          {
            required: true,
            message: "Please provide a Job Title",
            trigger: "blur",
          },
          {
            min: 3,
            max: 100,
            message: "Job Title must be between length 3 and 100",
            trigger: "blur",
          },
        ],
        subcategory: [
          {
            min: 3,
            max: 100,
            message: "Subcategory must be between length 3 and 100",
            trigger: "blur",
          },
        ],
        notes: [
          {
            max: 2000,
            message: "Notes can be no longer than 2000 characters",
            trigger: "blur",
          },
        ],
      },
      fields: null,
      pifsponsors: null,
      companies: null,
      categories: null,
      loading: false,
    };
  },
  async mounted() {
    this.loading = true;

    // Wait for the array of promises to be built
    // note that this is NOT awaiting the array of promises to *fulfill*
    const requests = await this.fetchAdminData();
    this.jobForm.id = this.$route.params.id;

    if (this.jobForm.id !== undefined) {
      requests.push(this.fetchJobDetails());
    } else {
      this.jobForm.id = 0;
    }

    await Promise.all(requests);
    this.loading = false;
  },
  methods: {
    async submitForm(formName) {

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const cDate = new Date();
          const year = cDate.getUTCFullYear();
          const month = cDate.getUTCMonth() + 1;
          const day = cDate.getUTCDate();
          // Set the updated date
          this.jobForm.lastupdated = `${year}-${month}-${day}`;

          try {
            await api.post("jobs", this.jobForm);
            this.$notify.success({
              title: "Success",
              message: "Job saved sucessfully",
              offset: 100,
            });
            this.$router.push("/jobs");
          } catch (e) {
            this.$notify.error({
              title: "Error",
              message: "Server encountered an error trying to save your job. Please try again later.",
              offset: 100,
            });
          }
        } else {
          this.$notify.error({
            title: "Error",
            message:
              "Job form failed validation. Ensure all required fields are correctly filled out.",
          });

          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.jobForm.id = 0;
      this.jobForm.companyid = "";
      this.jobForm.pifsponsorid = "";
      this.jobForm.fieldid = "";
    },
    async fetchAdminData() {
      const requests = [];
      requests.push(
        api.get("admin/field").then((fields) => (this.fields = fields))
      );
      requests.push(
        api
          .get("admin/categories")
          .then((categories) => (this.categories = categories))
      );

      requests.push(
        api.get("company").then((companies) => (this.companies = companies))
      );
      requests.push(
        api
          .get("users?request=ids")
          .then((sponsors) => (this.pifsponsors = sponsors.filter(
            sponsor => !sponsor.is_deleted
          )))
      );

      return requests;
    },
    async fetchJobDetails() {
      this.jobForm = await api.get("jobs/" + this.jobForm.id);
      this.jobForm.pifsponsorid = parseInt(this.jobForm.pifsponsorid);
      this.jobForm.fieldid = parseInt(this.jobForm.fieldid);
    },
  },
};
</script>
